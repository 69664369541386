/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { TiDelete } from "react-icons/ti";
import { BiSearch, BiMenu } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo1 from '../../Assets/images/logo/Blue Minimalist Investment Company Logo.png'

import {
  closeDropdown,
  closeSidebar,
  openSidebar,
  uiStore,
} from "../../features/uiSlice";
import { navLinks } from "../../data/navLinks";
import SingleLink from "./SingleLink";

const Navbar = () => {
  const rootDoc = document.querySelector(":root");
  const { darkMode, isSidebarOpen } = useSelector(uiStore);


  const dispatch = useDispatch();
  const [openGroup, setOpenGroup] = useState(null);

  const toggleGroup = (id) => {
    setOpenGroup(openGroup === id ? null : id);
  };

  const handleClose = (e) => {
    if (!e.target.classList.contains("link")) {
      dispatch(closeDropdown());
    }
  };

  const handleCloseSidebar = (e) => {
    if (e.target.classList.contains("mobile-modal")) dispatch(closeSidebar());
  };

  const handleNavigation = () => {
    dispatch(closeSidebar());
  };

  return (
    <>
      <div
        className="navbar absolute h-[100px] w-8/12 z-40 px-[3%] md:px-[6%] flex-center-between py-[0.35rem]"
        onMouseOver={handleClose}
      >
        <Link to="/" className="flex-shrink-0 flex-align-center gap-x-1 z-0 md:mt-20 mt-10 md:w-24 w-20 object-cover ">
          <img className="" src={logo1} alt="" />
        </Link>

          {/*-------------------------------------- Desktop Menu------------------------------------- */}
        <div className="flex-align-center rounded-full fixed right-[20px] py-2 md:right-[100px] z-10 px-4 gap-x-4 h-8 md:h-auto  bg-primary md:bg-primary/20 md:shadow-lg  backdrop-blur-2xl  "  data-wow-duration="3s" data-wow-delay="1s">
          <ul
            className="hidden md:flex-align-center "
          >
            {navLinks.map((link) => (
              <SingleLink {...link} key={link.id} />
            ))}
          </ul>
   


          {/*---------------------------------------- Mobile Menu------------------------------------- */}
          <div
            className={`lg:hidden mobile-modal fixed w-screen h-screen top-[-22px] right-[-20px] bg-black/50 z-50 opacity-0 pointer-events-none transition-a ${isSidebarOpen && "open"}`}
            onClick={handleCloseSidebar}
          >
            <ul
              className={`mobile-dialog overflow-auto absolute flex flex-col space-y-4 p-3 bg-white dark:bg-card-dark h-screen w-full transition-a ${isSidebarOpen && "open"}`}
            >
              <div className="border-b flex-center-between dark:border-slate-800">
                <p className="uppercase">Seaboard Property</p>
                <div
                  className="icon-box md:hidden"
                  onClick={() => dispatch(closeSidebar())}
                >
                  <div className="h-5 w-5 bg-slate-600 rounded-full flex justify-center items-center">
                    <TiDelete className="h-20 w-20 text-white" />
                  </div>
                </div>
              </div>
              {navLinks?.map(({ id, linkText, url }) => (
                <ul key={id}>
                  <li>
                    <button
                      onClick={() => {
                        handleNavigation();
                        toggleGroup(id);
                      }}
                      className="flex items-center w-fit before:!hidden"
                    >
                      <Link to={url} onClick={handleNavigation}>
                        <span className="ml-2">{linkText}</span>
                      </Link>
                    </button>
                  </li>
                </ul>
              ))}
            </ul>
          </div>

          <div className="space-x-2 flex-align-center">
            {/*------------------------------- Mobile Menu Toggle------------------------- */}
            <div
              className="icon-box text-white  md:hidden"
              onClick={() => dispatch(openSidebar())}
            >
              <BiMenu />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
