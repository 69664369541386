import { BiChevronDown } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { openDropdown } from "../../features/uiSlice";
import { useState, useEffect } from "react";

const SingleLink = ({ id, linkText, url, subLinks }) => {
  const [textColor, setTextColor] = useState("text-black");
  const dispatch = useDispatch();
  const location = useLocation();

  const handleDropDown = (e) => {
    const linkCords = e.target.getBoundingClientRect();
    const center = (linkCords.left + linkCords.right) / 2;
    dispatch(openDropdown({ link: linkText, center }));
  };

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight * 0.8) {
      setTextColor("text-black");
    } else {
      setTextColor("text-slate-50");
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      window.addEventListener("scroll", handleScroll);
      handleScroll(); 
    } else {
      setTextColor("text-black"); 
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  // Determine if the link should Not be clickable
  const isNonClickable = linkText === "Commercial" || linkText === "Residential";

  return (
    <div className="relative">
      <NavLink
        to={!isNonClickable ? url : "#"} 
        end
        key={id}
        className={({ isActive }) => 
          `relative w-full px-3 font-bold dark:text-slate-100 py-[0.4rem] lg:px-4 flex-align-center gap-x-1 link ${textColor} ${isActive && !isNonClickable ? 'text-primary' : ''}`
        }
        onMouseOver={subLinks ? handleDropDown : null} 
        onClick={isNonClickable ? (e) => e.preventDefault() : undefined} 
      >
        {linkText}
        {subLinks && <BiChevronDown className="link" />}
      </NavLink>
    </div>
  );
};

export default SingleLink;
