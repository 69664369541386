import React, { useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField"; 
import Autocomplete from "@mui/material/Autocomplete";
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import DubaiLocations from '../../../../data/dummyData';
import { useSearchParams } from 'react-router-dom';

const LocationSearch = ({ location, setLocation }) => {
  const [searchParams] = useSearchParams();

  // Effect to set initial location value from URL
  useEffect(() => {
    const urlLocation = searchParams.get('location');
    if (urlLocation) {
      setLocation(urlLocation);
    }
  }, [searchParams, setLocation]);

  return (
    <div className="flex items-center dark:bg-dark-light bg-slate-100 border rounded-full px-5 w-full md:w-auto">
      <SearchIcon />
      <Autocomplete
        disablePortal
        options={DubaiLocations}
        getOptionLabel={(option) => option.title}
        value={DubaiLocations.find(option => option.value === location) || null} // Set the current value based on the location state
        onChange={(event, newValue) => setLocation(newValue ? newValue.value : "")}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Location"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: "250px",
              border: "none",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <li className={`dark:bg-dark-light dark:text-white ${props.className}`} {...props}>
            <RoomOutlinedIcon style={{ marginRight: 8 }} />
            {option.title}
          </li>
        )}
      />
    </div>
  );
};

export default LocationSearch;
