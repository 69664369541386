import { BiMap } from "react-icons/bi";
import { FiMail, FiPhone } from "react-icons/fi";

const ContactInfo = () => {
  return (
    <div className="grid grid-cols-1 gap-6 py-16 sm:grid-cols-2 md:grid-cols-3">
      <div className="text-center border px-4 py-10 rounded-lg">
        <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl hover:text-secondary">
          <FiPhone />
        </div>
        <h1 className="mt-2 text-lg font-semibold">Phone Number</h1>
        <p>
          <a href="tel:+97142359233">
            +971 42 359 233
          </a>
        </p>
        <p>
          <a href="tel:+971503664079">
            +971 50 366 4079
          </a>
        </p>
      </div>
      <div className="text-center border px-4 py-10 rounded-lg">
        <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl hover:text-secondary">
          <FiMail />
        </div>
        <h1 className="mt-2 text-lg font-semibold">Email Address</h1>
        <p>
          <a href="mailto:info@seaboard.group">
            info@seaboard.group
          </a>
        </p>
        {/* <p>
          <a href="mailto:seaboard@gmail.com">
            seaboard@gmail.com
          </a>
        </p> */}
      </div>
      <div className="text-center border px-4 py-10 rounded-lg">
        <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl hover:text-secondary">
          <BiMap />
        </div>
        <h1 className="mt-2 text-lg font-semibold">Office Address</h1>
        <a href="https://maps.app.goo.gl/L6ZAuCqgrWfsbEhn7" target="_blank" rel="noopener noreferrer">
          <p>322, Al Wasl Sheikh Zayed Road,</p>
          <p>Dubai, United Arab Emirates</p>
        </a>
      </div>
    </div>
  );
};

export default ContactInfo;
