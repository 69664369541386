import React, { useState } from "react";
import { BiBed, BiMap, BiTab } from "react-icons/bi";
import { MdOutlineBed, MdOutlineBathtub } from "react-icons/md";

import { HiHomeModern } from "react-icons/hi2";
import { TbDimensions } from "react-icons/tb";
import { MdOutlinePhone, MdEmail } from "react-icons/md";
import {
  FaWhatsapp,
  FaRegArrowAltCircleRight,
  FaRegArrowAltCircleLeft,
} from "react-icons/fa";
import ImageGallery from "react-image-gallery";
import { useParams } from "react-router-dom";
import { property } from "../../../data/dummyData";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "emailjs-com";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "react-toastify/dist/ReactToastify.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import Modal from "react-modal";
import { BiHome, BiRuler, BiBath, BiCheck } from "react-icons/bi"; // Import icons
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles

const PropertyDetailPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openFullDescription, setOpenFullDescription] = useState(false);
  const [openFullAminities, setOpenFullAminities] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  
  const selectedProperty = property.find((p) => p.id === parseInt(id));

  if (!selectedProperty) {
    return <div>Property not found</div>;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const { name, email, phone, message } = formData;

    if (!name || !email || !phone || !message) {
      toast.error("Please fill out all required fields.");
      setIsSubmitting(false);
      return;
    }

    if (message.length < 20) {
      toast.error("Message must be at least 20 characters long.");
      setIsSubmitting(false);
      return;
    }

    const dataToSend = {
      ...formData,
      property_id: `221${selectedProperty.id}`,
    };

    emailjs
      .send(
        "service_c6dlw1v",
        "template_wotw8j9",
        dataToSend,
        "xl3P3SOjfCy-_DPZS"
      )
      .then(() => {
        toast.success("Your message has been sent successfully!");
        setFormData({ name: "", email: "", phone: "", message: "" });
        setIsModalOpen(false);
      })
      .catch(() => {
        toast.error("Failed to send your message. Please try again later.");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const openImageModal = (index) => {
    setCurrentSlide(index);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };
  const price = selectedProperty.price;
  const formatPrice = (price) => {
    // Convert to string and split into parts
    const strPrice = price.toString();
    const lastThreeDigits = strPrice.slice(-3);
    const otherDigits = strPrice.slice(0, -3);
    
    // Add commas for every two digits in the other digits part
    const formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    
    return otherDigits ? `${formattedOtherDigits},${lastThreeDigits}` : lastThreeDigits;
  };
  return (
    <>
      <div className="pt-48 px-4 md:px-8 lg:px-16">
        <h1 className="text-[12px]">
          HOME / <span className="text-primary">PROPERTY DETAILS</span>
        </h1>
        <div className="flex flex-col md:flex-row  rounded-lg border md:w-full mt-10">
          <div className="flex flex-col md:w-8/12  rounded-xl overflow-hidden">
        
            <img src={selectedProperty.image[0]} alt="" onClick={() => openImageModal()} className="cursor-pointer" />
          </div>

          <div className="flex md:block hidden flex-col my-auto gap-4 md:w-4/12 p-4">
            {selectedProperty.image.slice(2, 4).map((imgSrc, index) => (
              <img
                key={index}
                src={imgSrc}
                alt={`${selectedProperty.name} thumbnail ${index + 1}`}
                className="w-full max-h-80 mt-5 cursor-pointer rounded-md" 
                loading="lazy"
                onClick={() => openImageModal(index)} 
              />
            ))}
          </div>
        </div>

        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col md:flex-row py-4 md:w-8/12 border-b-2 px-4 justify-between items-center">
            <div className="font-extrabold sm:text-3xl md:text-[20px] lg:text-xl xl:text-3xl  mt-4">
              AED:{" "}
              <span className="text-primary">
  {formatPrice(price)}
  <span className="text-black/75">
    {selectedProperty.dd_MM_yy.length > 0 ? ` /  ${selectedProperty.dd_MM_yy} ` : ''}
  </span>
</span>

            </div>
            <div className="md:flex flex-wrap  hidden justify-end gap-4 mt-2 md:mt-0">
              <div className="flex items-center gap-2">
                <div className="icon-box  !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
                  <MdOutlineBed />
                </div>
                <p className="md:text-sm lg:text-md ">
                  {selectedProperty.number_of_beds} {selectedProperty.bedsMaid}{" "}
                  {selectedProperty.maid}
                </p>
              </div>
              <span>|</span>
              <div className="flex items-center gap-2">
                <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
                  <MdOutlineBathtub />
                </div>
                <p className="md:text-sm lg:text-md ">
                  {selectedProperty.number_of_bathrooms} Bathrooms
                </p>
              </div>
              <span>|</span>

              <div className="md:flex items-center  hidden gap-2">
                <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
                  <TbDimensions />
                </div>
                <p className="md:text-sm lg:text-md ">Sqft: {selectedProperty.dimensions}</p>
              </div>
            </div>
          </div>

          <div className="mt-4 md:mt-2 md:w-4/12">
            <div className="flex md:flex-col flex-col gap-4 xl:flex-row justify-center">
              <a
                href={`https://wa.me/${selectedProperty.phone}`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary flex items-center justify-center"
              >
                <FaWhatsapp className="mr-2" /> Whatsapp
              </a>
              <a
                href={`mailto:${selectedProperty.email}`}
                className="btn btn-primary flex items-center justify-center"
              >
                <MdEmail className="mr-2" /> Email
              </a>
              <button
                onClick={() => setIsModalOpen(true)}
                className="btn btn-primary flex items-center justify-center"
              >
                <MdOutlinePhone className="mr-2" /> Contact
              </button>
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="bg-gray-50  shadow-sm w-full md:w-8/12 dark:bg-gray-800 text-gray-900 dark:text-slate-400 p-4 mt-10 rounded-lg">
            <div className="p-5 ">
              <p className="text-2xl dark:text-white">
                {selectedProperty.description[0].heading}
              </p>
              <p className="text-2xl mt-2 font-extrabold ">
                {selectedProperty.name}
              </p>
              <p className="md:text-lg xl:text-xl text-sm mt-2 dark:text-white">
                {selectedProperty.description[0].Paragraph}
              </p>

              {selectedProperty.description.map(
                (section, index) =>
                  section.heading === "Property Details" && (
                    <div key={index}>
                      <h2 className="text-lg dark:text-slate-300 mt-4 mb-4">
                        {section.heading}
                      </h2>
                      <h2 className="">{section.Paragraph}</h2>
                      <div>
                        {section.content
                          .split("\n")
                          .slice(0, 9)
                          .map((line, lineIndex) => (
                            <p className="md:text-lg text-md" key={lineIndex}>
                              {line}
                            </p>
                          ))}
                        <button
                          onClick={() => setOpenFullDescription(true)}
                          className="btn btn-primary mt-5"
                        >
                          see Full Description
                        </button>
                      </div>
                      <div className="mt-5">
                        <hr />
                      </div>
                      <div className="pt-5 ">
                        <h1 className="heading">Property Details</h1>
                        <div className="grid grid-cols-1 py-4 md:grid-cols-2 gap-4 mt-4">
                          <div className="flex items-center">
                          <div className="icon-box !w-7 !h-7 mr-2 bg-primary/20 hover:!bg-primary/40 text-primary">
                              
                            <BiHome  />
                            </div>
                            <h1 className="md:text-1xl ">
                              Property Type:{" "}
                              <span className="font-bold">
                                {" "}
                                {selectedProperty.propertyType}
                              </span>
                            </h1>
                          </div>
                          <div className="flex items-center">
                            <div className="icon-box !w-7 !h-7 mr-2 bg-primary/20 hover:!bg-primary/40 text-primary">
                              <TbDimensions />
                            </div>
                            <h1 className="md:text-1xl txet-xs">
                              Property Size: <span className="font-bold"> {selectedProperty.dimensions}</span>
                            </h1>
                          </div>
                          <div className="flex items-center">
                            <div className="icon-box !w-7 !h-7 mr-2 bg-primary/20 hover:!bg-primary/40 text-primary">
                              <MdOutlineBed />
                            </div>
                            <h1 className="md:text-1xl txet-xs">
                            Bedrooms: <span className="font-semibold">
    {selectedProperty.number_of_beds} 
    {selectedProperty.bedsMaid === "Studio" ? "Studio" : selectedProperty.bedsMaid !== "Bedroom" ? selectedProperty.bedsMaid : ""}
</span>


                            </h1>
                          </div>
                          <div className="flex items-center">
                            <div className="icon-box !w-7 !h-7 mr-2 bg-primary/20 hover:!bg-primary/40 text-primary">
                              <BiBath />
                            </div>
                            <h1 className="md:text-1xl txet-xs">
                              Bathrooms: <span className="font-semibold"> {selectedProperty.number_of_bathrooms}  </span>
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className="mt-5">
                            <hr />
                          </div>
                          <div className="pt-5">
                            <h1 className="heading">Amenities</h1>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                              {selectedProperty.amenities
                                .slice(0, 8)
                                .map((amenity, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center   "
                                  >
                                    <BiCheck className="text-md mr-2 text-primary" />
                                    <span className="text-md">
                                      {amenity.replace(/^- /, "")}
                                    </span>
                                  </div>
                                ))}
                            </div>
                            <button
                              onClick={() => setOpenFullAminities(true)}
                              className="btn btn-primary mt-5"
                            >
                              see all Amenities
                            </button>
                          </div>
                        </div>

                        <div></div>
                      </div>
                    </div>
                  )
              )}

              {openFullDescription && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                  <div className="md:w-8/12 h-4/6 w-9/12 rounded-lg bg-slate-100 border dark:bg-dark">
                    <div>
                      <div className="flex justify-between px-4 py-2 bg-slate-100 shadow-sm">
                        <h1 className="md:text-2xl text-1xl text-secondary dark:text-slate-300">
                          Full Description
                        </h1>
                        <button
                          onClick={() => setOpenFullDescription(false)}
                          className="btn btn-primary"
                        >
                          Close
                        </button>
                      </div>
                      <div
                        className="p-5 overflow-auto"
                        style={{ maxHeight: "calc(80vh - 200px)" }}
                      >
                        {selectedProperty.description.map((section, index) => (
                          <div key={index} className="mb-4">
                            <h2 className="text-lg dark:text-slate-300">
                              {section.heading}
                            </h2>
                            <h2>{section.Paragraph}</h2>
                            <div>
                              {section.content
                                .split("\n")
                                .map((line, lineIndex) => (
                                  <p className="text-lg mt-1" key={lineIndex}>
                                    {line}
                                  </p>
                                ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {openFullAminities && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                  <div className="md:w-8/12 lg:w-6/12 w-9/12 h-auto rounded-lg bg-slate-100 border dark:bg-dark overflow-hidden">
                    <div>
                      <div className="flex justify-between px-4 py-2 bg-slate-100 shadow-sm">
                        <h1 className="md:text-2xl text-secondary dark:text-slate-300">
                          Amenities
                        </h1>
                        <button
                          onClick={() => setOpenFullAminities(false)}
                          className="btn btn-primary"
                        >
                          Close
                        </button>
                      </div>
                      <div
                        className="p-5 overflow-y-auto"
                        style={{ maxHeight: "calc(70vh - 100px)" }}
                      >
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                          {selectedProperty.amenities.map((amenity, index) => (
                            <div key={index} className="flex items-center p-2">
                              <BiCheck className="text-2xl mr-2 text-primary" />
                              <span className="text-lg">
                                {amenity.replace(/^- /, "")}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-4/12 md:block hidden pt-10 h-[40rem]">
            <iframe
              src={selectedProperty.map}
              height="100%"
              width="70%"
              className="m-auto md:block hidden"
              frameborder="0"
            ></iframe>
          </div>
        </div>
        {isModalOpen && (
          <div
            id="default-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50"
          >
            <div className="md:w-4/12 w-9/12 rounded-lg bg-slate-100 border dark:bg-dark">
              <div className="flex items-center justify-end p-4 border-b rounded-t dark:border-gray-600">
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => setIsModalOpen(false)}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>
              </div>

              <div className="max-w-[450px] w-full mx-auto p-3 rounded-lg">
                <div className="mt-4">
                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full px-2 py-1 border rounded-md outline-none bg-transparent"
                      placeholder="Your name.."
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full px-2 py-1 mt-3 border rounded-md outline-none bg-transparent"
                      placeholder="Your email.."
                    />
                    <input
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      className="w-full px-2 py-1 mt-3 border rounded-md outline-none bg-transparent"
                      placeholder="Your Phone No.."
                      required
                    />
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      className="w-full p-2 mt-3 border rounded-md outline-none bg-transparent"
                      rows={3}
                      placeholder="Your message.."
                      required
                      minLength={20}
                    ></textarea>
                    <button
                      type="submit"
                      className="w-full mt-4 btn btn-primary"
                    >
                      Submit
                    </button>
                  </form>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Image Modal */}
        {isImageModalOpen && (
          <Modal
  isOpen={isImageModalOpen}
  onRequestClose={closeImageModal}
  
  className="fixed inset-0 z-1000 flex items-center justify-center bg-black bg-opacity-90 "
  overlayClassName="fixed z-1000 inset-0 bg-black bg-opacity-50"
>
  <Carousel  showArrows={true} className="px-5 w-full max-w-3xl h-full py-32 m-auto">
    {selectedProperty.image.map((image, index) => (
      <div  key={index} className="flex  justify-center my-auto h-full items-center m-auto">
        <img
          src={image}
          className="object-cover w-full  rounded-lg"
          alt={`Property image ${index + 1}`}
        />
      </div>
    ))}
  </Carousel>
  <button
    onClick={closeImageModal}
    className="absolute top-10 right-10 text-white text-1xl md:text-4xl"
    aria-label="Close image modal"
  >
    &times;
  </button>
</Modal>



  
    )}
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        pauseOnFocusLoss
        theme="light"
      />
    </>
  );
};

export default PropertyDetailPage;
