import { useState } from 'react';
import emailjs from 'emailjs-com';
import { BiMap } from "react-icons/bi";
import { BsEnvelope, BsTelephoneOutboundFill } from "react-icons/bs";
import { FaInstagram, FaLinkedin, FaTiktok } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import footerlogo from '../../../src/Assets/images/logo/Blue Minimalist Investment Company Logo.png';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubscribe = (e) => {
    e.preventDefault();
    
    if (!email) {
      setMessage('Please enter a valid email address.');
      return;
    }

    const templateParams = {
      to_email: 'talharao944@gmail.com', // The email address to send to
      from_email: email, // The email address from which the subscription request came
      message: `Hello there, I subscribe your website with the email: ${email}`
    };

    emailjs.send('service_c6dlw1v', 'template_iuvc8b7', templateParams, 'xl3P3SOjfCy-_DPZS')
      .then(response => {
        setMessage('Subscription successful!');
        setEmail('');
      })
      .catch(error => {
        setMessage('Subscription failed. Please try again later.');
      });
  };

  return (
    <footer className="text-slate-700  dark:text-slate-300 py-8">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex flex-wrap gap-8 md:gap-16">
          {/* Logo Section */}
          <div className="md:flex-1 flex justify-center items-center w-full m-auto">
            <img src={footerlogo} alt="Footer Logo" className="w-20  md:w-32" />
          </div>

          {/* Social Media Links */}
          <div className="flex-2">
            <h2 className="text-xl font-semibold mb-4">Social Handles</h2>
            <div className="flex gap-4">
              {/* <a href="#" className="icon-box text-slate-200 bg-primary/50 hover:bg-secondary hover:text-primary">
                <FiFacebook className="text-2xl" />
              </a> */}
              {/* <a href="#" className="icon-box text-slate-200 bg-primary/50 hover:bg-secondary hover:text-primary">
                <FaTiktok className="text-2xl" />
              </a> */}
            <a href="https://www.instagram.com/seaboardproperty/?igsh=ZWdhNWFhemMxdHBj#" className="icon-box text-slate-200 bg-primary/50 hover:bg-secondary hover:text-primary" target="_blank" rel="noopener noreferrer">
    <FaInstagram className="text-2xl" />
</a>
<a href="https://pk.linkedin.com/company/seaboard" className="icon-box text-slate-100 bg-primary/50 hover:bg-secondary hover:text-primary" target="_blank" rel="noopener noreferrer">
    <FaLinkedin className="text-2xl" />
</a>

            </div>
          </div>

          {/* Contact Information */}
          <div className="flex-1">
  <h2 className="text-xl font-semibold mb-4">Contact</h2>
  <ul className="space-y-3">
    <li className="text-muted flex items-center gap-2">
      <a href="https://maps.google.com/?q=322,+Al+Wasl+Sheikh+Zayed+Road,+Dubai,+UAE" className="flex items-center gap-2">
        <BiMap className="text-primary text-lg" />
        <span className="truncate">322, Al Wasl Sheikh Zayed Road, Dubai, UAE</span>
      </a>
    </li>
    <li className="text-muted flex items-center gap-2">
      <a href="tel:+97142359233" className="flex items-center gap-2">
        <BsTelephoneOutboundFill className="text-primary text-lg" />
        <p>+971 42 359 233</p>
        <p>+971 50 366 4079</p>
      </a>
    </li>

    <li className="text-muted flex items-center gap-2">
      <a href="mailto:info@seaboard.group" className="flex items-center gap-2">
        <BsEnvelope className="text-primary text-lg" />
        <span>info@seaboard.group</span>
      </a>
    </li>
  </ul>
</div>


          {/* Newsletter Subscription */}
          <div className="flex-1 text-center md:text-left">
            <h2 className="text-xl font-semibold mb-4">Subscribe to our Newsletter</h2>
            <p className="text-sm text-muted mb-4">
              Subscribe to be the first one to know about updates. Enter your email below:
            </p>
            <form onSubmit={handleSubscribe} className="flex flex-col md:flex-row items-center gap-4">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="px-4 py-2 bg-transparent border border-dark rounded-lg outline-none"
                placeholder="Email Address..."
              />
              <button type="submit" className="btn btn-primary mt-2 md:mt-0">Subscribe</button>
            </form>
            {message && <p className="mt-4 text-sm">{message}</p>}
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="text-center border-t border-dark pt-4 mt-6 text-sm">
          <p>
            Created By <span className="text-primary">Seaboard Group</span> | All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
