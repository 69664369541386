// id # 01 Images
import id1img1 from '../Assets/images1/images/property-1/1.webp';
import id1img2 from '../Assets/images1/images/property-1/2.webp';
import id1img3 from '../Assets/images1/images/property-1/3.webp';
import id1img4 from '../Assets/images1/images/property-1/4.webp';
import id1img5 from '../Assets/images1/images/property-1/5.webp';
import id1img6 from '../Assets/images1/images/property-1/6.webp';
import id1img7 from '../Assets/images1/images/property-1/7.webp';
import id1img8 from '../Assets/images1/images/property-1/8.webp';
import id1img9 from '../Assets/images1/images/property-1/9.webp';
import id1img10 from '../Assets/images1/images/property-1/10.webp';
import id1img11 from '../Assets/images1/images/property-1/11.webp';
import id1img12 from '../Assets/images1/images/property-1/12.webp';
import id1img13 from '../Assets/images1/images/property-1/13.webp';
import id1img14 from '../Assets/images1/images/property-1/14.webp';
import id1img15 from '../Assets/images1/images/property-1/15.webp';
import id1img16 from '../Assets/images1/images/property-1/16.webp';
import id1img17 from '../Assets/images1/images/property-1/17.webp';
import id1img18 from '../Assets/images1/images/property-1/18.webp';
import id1img19 from '../Assets/images1/images/property-1/19.webp';
import id1img20 from '../Assets/images1/images/property-1/20.webp';
import id1img21 from '../Assets/images1/images/property-1/21.webp';
import id1img22 from '../Assets/images1/images/property-1/22.webp';
import id1img23 from '../Assets/images1/images/property-1/23.webp';
import id1img24 from '../Assets/images1/images/property-1/24.webp';
import id1img25 from '../Assets/images1/images/property-1/25.webp';
import id1img26 from '../Assets/images1/images/property-1/26.webp';
import id1img27 from '../Assets/images1/images/property-1/27.webp';
import id1img28 from '../Assets/images1/images/property-1/28.webp';
import id1img29 from '../Assets/images1/images/property-1/29.webp';
import id1img30 from '../Assets/images1/images/property-1/30.webp';
import warehouse from '../Assets/images/WarehouseCat.jpg'
import duplex from '../Assets/images/dupleximg.jpg'
import townhouse from '../Assets/images/townhouse.jfif'
import shops from '../Assets/images/subaishops.jpg'
import ReactStars from "react-rating-stars-component";

// id # 02 images 
import id2img1 from '../Assets/images1/images/property-2/img1.webp';
import id2img2 from '../Assets/images1/images/property-2/img2.webp';
import id2img3 from '../Assets/images1/images/property-2/img3.webp';
import id2img4 from '../Assets/images1/images/property-2/img4.webp';
import id2img5 from '../Assets/images1/images/property-2/img5.webp';
import id2img6 from '../Assets/images1/images/property-2/img6.webp';
import id2img7 from '../Assets/images1/images/property-2/img7.webp';
import id2img8 from '../Assets/images1/images/property-2/img8.webp';
import id2img9 from '../Assets/images1/images/property-2/img9.webp';
import id2img10 from '../Assets/images1/images/property-2/img10.webp';
import id2img11 from '../Assets/images1/images/property-2/img11.webp';
import id2img12 from '../Assets/images1/images/property-2/img12.webp';
import id2img13 from '../Assets/images1/images/property-2/img13.webp';
import id2img14 from '../Assets/images1/images/property-2/img14.webp';
import id2img15 from '../Assets/images1/images/property-2/img15.webp';
import id2img16 from '../Assets/images1/images/property-2/img16.webp';
import id2img17 from '../Assets/images1/images/property-2/img17.webp';
import id2img18 from '../Assets/images1/images/property-2/img18.webp';
import id2img19 from '../Assets/images1/images/property-2/img19.webp';
import id2img20 from '../Assets/images1/images/property-2/img20.webp';
import id2img21 from '../Assets/images1/images/property-2/img21.webp';
import id2img22 from '../Assets/images1/images/property-2/img22.webp';
import id2img23 from '../Assets/images1/images/property-2/img23.webp';
import id2img24 from '../Assets/images1/images/property-2/img24.webp';
import id2img25 from '../Assets/images1/images/property-2/img25.webp';
import id2img26 from '../Assets/images1/images/property-2/img26.webp';
import id2img27 from '../Assets/images1/images/property-2/img27.webp';
import id2img28 from '../Assets/images1/images/property-2/img28.webp';
import id2img29 from '../Assets/images1/images/property-2/img29.webp';




// id # 03 images 
import id3img1 from '../Assets/images1/images/property-3/img1.webp';
import id3img2 from '../Assets/images1/images/property-3/img2.webp';
import id3img3 from '../Assets/images1/images/property-3/img3.webp';
import id3img4 from '../Assets/images1/images/property-3/img4.webp';
import id3img5 from '../Assets/images1/images/property-3/img5.webp';
import id3img6 from '../Assets/images1/images/property-3/img6.webp';
import id3img7 from '../Assets/images1/images/property-3/img7.webp';
import id3img8 from '../Assets/images1/images/property-3/img8.webp';
import id3img9 from '../Assets/images1/images/property-3/img9.webp';
import id3img10 from '../Assets/images1/images/property-3/img10.webp';
import id3img11 from '../Assets/images1/images/property-3/img11.webp';
import id3img12 from '../Assets/images1/images/property-3/img12.webp';
import id3img13 from '../Assets/images1/images/property-3/img13.webp';
import id3img14 from '../Assets/images1/images/property-3/img14.webp';
import id3img15 from '../Assets/images1/images/property-3/img15.webp';
import id3img16 from '../Assets/images1/images/property-3/img16.webp';
import id3img17 from '../Assets/images1/images/property-3/img17.webp';
import id3img18 from '../Assets/images1/images/property-3/img18.webp';
import id3img19 from '../Assets/images1/images/property-3/img19.webp';
import id3img20 from '../Assets/images1/images/property-3/img20.webp';
import id3img21 from '../Assets/images1/images/property-3/img21.webp';
import id3img22 from '../Assets/images1/images/property-3/img22.webp';
import id3img23 from '../Assets/images1/images/property-3/img23.webp';
import id3img24 from '../Assets/images1/images/property-3/img24.webp';
import id3img25 from '../Assets/images1/images/property-3/img25.webp';
import id3img26 from '../Assets/images1/images/property-3/img26.webp';
import id3img27 from '../Assets/images1/images/property-3/img27.webp';
import id3img28 from '../Assets/images1/images/property-3/img28.webp';
import id3img29 from '../Assets/images1/images/property-3/img29.webp';
import id3img30 from '../Assets/images1/images/property-3/img30.jpg';

// id # 04 images 
import id4img1 from '../Assets/images1/images/property-9/img1.webp';
import id4img2 from '../Assets/images1/images/property-9/img2.webp';
import id4img3 from '../Assets/images1/images/property-9/img3.webp';
import id4img4 from '../Assets/images1/images/property-9/img4.webp';
import id4img5 from '../Assets/images1/images/property-9/img5.webp';
import id4img6 from '../Assets/images1/images/property-9/img6.webp';
import id4img7 from '../Assets/images1/images/property-9/img7.webp';
import id4img8 from '../Assets/images1/images/property-9/img8.webp';
import id4img9 from '../Assets/images1/images/property-9/img9.webp';
import id4img10 from '../Assets/images1/images/property-9/img10.webp';
import id4img11 from '../Assets/images1/images/property-9/img11.webp';
import id4img12 from '../Assets/images1/images/property-9/img12.webp';
import id4img13 from '../Assets/images1/images/property-9/img13.webp';
import id4img14 from '../Assets/images1/images/property-9/img14.webp';
import id4img15 from '../Assets/images1/images/property-9/img15.webp';
import id4img16 from '../Assets/images1/images/property-9/img16.webp';
import id4img17 from '../Assets/images1/images/property-9/img17.webp';
import id4img18 from '../Assets/images1/images/property-9/img18.webp';
import id4img19 from '../Assets/images1/images/property-9/img19.webp';
import id4img20 from '../Assets/images1/images/property-9/img20.webp';
import id4img21 from '../Assets/images1/images/property-9/img21.webp';
import id4img22 from '../Assets/images1/images/property-9/img22.webp';
import id4img23 from '../Assets/images1/images/property-9/img23.webp';
import id4img24 from '../Assets/images1/images/property-9/img24.webp';
import id4img25 from '../Assets/images1/images/property-9/img25.webp';
import id4img26 from '../Assets/images1/images/property-9/img26.webp';
import id4img27 from '../Assets/images1/images/property-9/img27.webp';
import id4img28 from '../Assets/images1/images/property-9/img28.webp';
import id4img29 from '../Assets/images1/images/property-9/img29.webp';
import id4img30 from '../Assets/images1/images/property-9/img30.jpg';




// id # 04 images 
import id7img1 from '../Assets/images1/images/property-4/img1.webp';
import id7img2 from '../Assets/images1/images/property-4/img2.webp';
import id7img3 from '../Assets/images1/images/property-4/img3.webp';
import id7img4 from '../Assets/images1/images/property-4/img4.webp';
import id7img5 from '../Assets/images1/images/property-4/img5.webp';
import id7img6 from '../Assets/images1/images/property-4/img6.webp';
import id7img7 from '../Assets/images1/images/property-4/img7.webp';
import id7img8 from '../Assets/images1/images/property-4/img8.webp';
import id7img9 from '../Assets/images1/images/property-4/img9.webp';
import id7img10 from '../Assets/images1/images/property-4/img10.webp';
import id7img11 from '../Assets/images1/images/property-4/img11.jpg';

// id # 05 images 
import id5img1 from '../Assets/images1/images/property-5/img1.webp';
import id5img2 from '../Assets/images1/images/property-5/img2.webp';
import id5img3 from '../Assets/images1/images/property-5/img3.webp';
import id5img4 from '../Assets/images1/images/property-5/img4.webp';
import id5img5 from '../Assets/images1/images/property-5/img5.jpg';
import id5img6 from '../Assets/images1/images/property-5/img6.jpeg';
import id5img7 from '../Assets/images1/images/property-5/img7.jpeg';
import id5img8 from '../Assets/images1/images/property-5/img8.jpeg';


// id # 09 images 
import id9img1 from '../Assets/images1/images/property-6/img1.webp';
import id9img2 from '../Assets/images1/images/property-6/img2.webp';
import id9img3 from '../Assets/images1/images/property-6/img3.webp';
import id9img4 from '../Assets/images1/images/property-6/img4.webp';
import id9img5 from '../Assets/images1/images/property-6/img5.webp';
import id9img6 from '../Assets/images1/images/property-6/img6.webp';
import id9img7 from '../Assets/images1/images/property-6/img7.webp';
import id9img8 from '../Assets/images1/images/property-6/img8.webp';
import id9img9 from '../Assets/images1/images/property-6/img9.webp';
import id9img10 from '../Assets/images1/images/property-6/img10.webp';
import id9img11 from '../Assets/images1/images/property-6/img11.webp';
import id9img12 from '../Assets/images1/images/property-6/img12.webp';
import id9img13 from '../Assets/images1/images/property-6/img13.webp';
import id9img14 from '../Assets/images1/images/property-6/img14.webp';
import id9img15 from '../Assets/images1/images/property-6/img15.jpg';


// id # 06 images 
import id8img1 from '../Assets/images1/images/property-10/img1.webp';
import id8img2 from '../Assets/images1/images/property-10/img2.webp';
import id8img3 from '../Assets/images1/images/property-10/img3.webp';
import id8img4 from '../Assets/images1/images/property-10/img4.webp';
import id8img5 from '../Assets/images1/images/property-10/img5.webp';
import id8img6 from '../Assets/images1/images/property-10/img6.webp';
import id8img7 from '../Assets/images1/images/property-10/img7.webp';
import id8img8 from '../Assets/images1/images/property-10/img8.webp';
import id8img9 from '../Assets/images1/images/property-10/img9.webp';
import id8img10 from '../Assets/images1/images/property-10/img10.webp';
import id8img11 from '../Assets/images1/images/property-10/img11.webp';
import id8img12 from '../Assets/images1/images/property-10/img12.webp';
import id8img13 from '../Assets/images1/images/property-10/img13.webp';
import id8img14 from '../Assets/images1/images/property-10/img14.webp';
import id8img15 from '../Assets/images1/images/property-10/img15.jpg';


import id10img1 from '../Assets/images1/images/property-7/img1.webp';
import id10img2 from '../Assets/images1/images/property-7/img2.webp';
import id10img3 from '../Assets/images1/images/property-7/img3.webp';
import id10img4 from '../Assets/images1/images/property-7/img4.webp';
import id10img5 from '../Assets/images1/images/property-7/img5.webp';
import id10img6 from '../Assets/images1/images/property-7/img6.webp';
import id10img7 from '../Assets/images1/images/property-7/img7.webp';
import id10img8 from '../Assets/images1/images/property-7/img8.webp';
import id10img9 from '../Assets/images1/images/property-7/img9.webp';
import id10img10 from '../Assets/images1/images/property-7/img10.webp';
import id10img11 from '../Assets/images1/images/property-7/img11.webp';
import id10img12 from '../Assets/images1/images/property-7/img12.webp';
import id10img13 from '../Assets/images1/images/property-7/img13.webp';
import id10img14 from '../Assets/images1/images/property-7/img14.webp';
import id10img15 from '../Assets/images1/images/property-7/img15.webp';
import id10img16 from '../Assets/images1/images/property-7/img16.webp';
import id10img17 from '../Assets/images1/images/property-7/img17.webp';
import id10img18 from '../Assets/images1/images/property-7/img18.webp';
import id10img19 from '../Assets/images1/images/property-7/img19.webp';
import id10img20 from '../Assets/images1/images/property-7/img20.webp';
import id10img21 from '../Assets/images1/images/property-7/img21.webp';
import id10img22 from '../Assets/images1/images/property-7/img22.webp';
import id10img23 from '../Assets/images1/images/property-7/img23.webp';
import id10img24 from '../Assets/images1/images/property-7/img24.webp';
import id10img25 from '../Assets/images1/images/property-7/img25.webp';
import id10img26 from '../Assets/images1/images/property-7/img26.webp';
import id10img27 from '../Assets/images1/images/property-7/img27.webp';
import id10img28 from '../Assets/images1/images/property-7/img28.webp';
import id10img29 from '../Assets/images1/images/property-7/img29.webp';
import id10img30 from '../Assets/images1/images/property-7/img30.jpg';


// blog img


import casino from '../Assets/images/blogsimg/Casino Island.jpg';
import newcasino from '../Assets/images/blogsimg/new_casino.webp'; 
import newjunction from '../Assets/images/blogsimg/newjunction.jpg';
import dubaiairport from '../Assets/images/blogsimg/dubainewairportimg.jpg';
import formula1 from '../Assets/images/blogsimg/formula1.jpg';
import t20worldcup from '../Assets/images/blogsimg/t20worldcup.jfif';



import img10 from '../Assets/images1/images/property (17).jpg';

import {

  BiBuildings,
  BiFullscreen,
  BiHomeAlt,
  BiBarChart,
  BiMoney,
  BiShieldAlt2,
} from "react-icons/bi";
// import { BsStarFill } from "react-icons/bs";
import {
  FaBehance,
  FaDribbble,
  FaFacebook,
  FaInstagram,
  FaLinkedin,

  FaTwitter,
  FaVimeo,
  FaYoutube,
} from "react-icons/fa";










// src/components/home/home/top100Films.js
const DubaiLocations = [
  { title: 'Burj Khalifa', value: 'burjKhalifa' },
  { title: 'Down Town', value: 'downtown' },
  { title: 'Dubai Mall', value: 'dubaiMall' },
  { title: 'Palm Jumeirah', value: 'palmJumeirah' },
  { title: 'Dubai Marina', value: 'dubaiMarina' },
  { title: 'Jumeirah Beach', value: 'jumeirahBeach' },
  { title: 'Burj Al Arab', value: 'burjAlArab' },
  { title: 'Dubai Creek', value: 'dubaiCreek' },
  { title: 'Desert Safari', value: 'desertSafari' },
  { title: 'Dubai Frame', value: 'dubaiFrame' },
  { title: 'Dubai Aquarium', value: 'dubaiAquarium' },
  { title: 'Global Village', value: 'globalVillage' },
  { title: 'Ski Dubai', value: 'skiDubai' },
  { title: 'Dubai Opera', value: 'dubaiOpera' },
  { title: 'Museum of the Future', value: 'museumOfTheFuture' },
  { title: 'Jumeirah Mosque', value: 'jumeirahMosque' },
  { title: 'Dubai Gold Souk', value: 'dubaiGoldSouk' },
  { title: 'The Walk at JBR', value: 'theWalkAtJBR' },
  { title: 'Aquaventure Waterpark', value: 'aquaventureWaterpark' },
  { title: 'City Walk', value: 'cityWalk' },
  { title: 'Wild Wadi Water Park', value: 'wildWadiWaterPark' },
  { title: 'The Dubai Fountain', value: 'theDubaiFountain' },
  { title: 'Al Fahidi Historical Neighborhood', value: 'alFahidiHistoricalNeighborhood' },
  { title: 'Dubai Miracle Garden', value: 'dubaiMiracleGarden' },
  { title: 'Jumeirah Beach Residence', value: 'jumeirahBeachResidence' },
  { title: 'Dubai Design District', value: 'dubaiDesignDistrict' },
  { title: 'Dubai Sports City', value: 'dubaiSportsCity' },
  { title: 'Dubai Marina Yacht Club', value: 'dubaiMarinaYachtClub' },
  { title: 'The Lost Chambers Aquarium', value: 'theLostChambersAquarium' },
  { title: 'Ras Al Khor Wildlife Sanctuary', value: 'rasAlKhorWildlifeSanctuary' },
  { title: 'Dubai Dolphinarium', value: 'dubaiDolphinarium' },
  { title: 'Dubai Water Canal', value: 'dubaiWaterCanal' },
  { title: 'Al Qudra Lakes', value: 'alQudraLakes' },
  { title: 'The Pointe', value: 'thePointe' },
  { title: 'Safa Park', value: 'safaPark' },
  { title: 'Dubai Creek Golf & Yacht Club', value: 'dubaiCreekGolfAndYachtClub' },
  { title: 'Dubai Festival City Mall', value: 'dubaiFestivalCityMall' },
];


export default DubaiLocations;























export const property = [
  {
 id: 1,
    name: "Best Corner Large Plot| High ROI",
    location: "Farm Gardens - The Valley - Dubai",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 6250000,
    distance: "2km",
    purpose: "Sale",
  bedsMaid:'Bedroom',
  dd_MM_yy: "",

    puposesearch:'Residential',
    number_of_beds: 4,
    number_of_bathrooms: 5,
    commercialsearch:"",
    maid:"+ Maid",
    propertyType: "Villa",
    contact: "+971503231194",
    dimensions: 9808,
    amenities: [
      
      "Grand Lawn",
      "Petting Zoo & Animal Farm",
      "Kids Play Area",
      "Hydroponics",
      "Community Farming",
      "Desert Majlis & Bonfire",
      "Stargazing Platforms",
      "Picnic Spots",
      "Arrival Plaza",
      "Outdoor Fitness Station",
      "Yoga/Events Lawn",
      "Xeriscape Botanical Garden",
      "Events Plaza",
      "Pool Deck",
      "Padel Court",
      "Volleyball",
      "Ghaf Forest"
    ],
    description: [
      {
        heading: "Villa for sale in Farm Gardens, The Valley",
        Paragraph:`
        Investing in Farm Gardens at The Valley with Seaboard Properties combines real estate expertise with the rising demand for sustainable agriculture, offering a smart and eco-friendly investment opportunity.`
,
        content: ""
      },
      {
        heading: "Property Details",
        content: `- Corner Large Plot\n
        - Community: Farm Gardens, The Valley, Al Ain Road\n
        - Size: 9,808 sq. ft.\n
        - Bedrooms: 4 + Maid's Room with Ensuite\n
        - Bathrooms: 5\n
        - Garage\n
        - Laundry Room\n
        - Lift\n
        - Private Pool\n
        - Roof Floor with Family Room and Bath\n
        - Balconies: 4 (Bedroom 2, Bedroom 3, Master Room, Roof Floor)\n
        - Flexible Payment Plan`
      },
      {
        heading: "Selling Price",
        content: `AED 6,500,000`
      },
      {
        heading: "Amenities",
        content: `- Grand Lawn\n
        - Petting Zoo & Animal Farm\n
        - Kids Play Area\n
        - Hydroponics\n
        - Community Farming\n
        - Desert Majlis & Bonfire\n
        - Stargazing Platforms\n
        - Picnic Spots\n
        - Arrival Plaza\n
        - Outdoor Fitness Station\n
        - Yoga/Events Lawn\n
        - Xeriscape Botanical Garden\n
        - Events Plaza\n
        - Pool Deck\n
        - Padel Court\n
        - Volleyball\n
        - Ghaf Forest`
      },
      {
        heading: "Locations/Near Attractions",
        content: `- 5 mins to Rugby Sevens Stadium\n
        - 8 mins from Dubai Outlet Mall\n
        - 25 mins from Burj Khalifa & Downtown Dubai\n
        - 25 mins from Dubai International Airport`
      },
      {
        heading: "Community Overview",
        content: `Farm Gardens at The Valley by Emaar is a peaceful community in Dubai featuring luxurious farmhouse-style Villa. Surrounded by lush greenery and agricultural spaces, it offers a perfect blend of modern living and rustic, countryside charm with top-notch amenities for a relaxed lifestyle.`
      },
      {
        heading: "Contact Information",
        content: `For more details, please contact Seaboard Properties LLC at 050 323 1194 or 04-2359233.\n
        BRN No. 71148 | ORN No. 1903.`
      }
    ],


  image : [ 
    id1img1, 
    id1img2, 
    id1img3, 
    id1img4, 
    id1img5, 
    id1img6, 
    id1img7, 
    id1img8, 
    id1img9, 
    id1img10, 
    id1img11, 
    id1img12, 
    id1img13, 
    id1img14, 
    id1img15, 
    id1img16, 
    id1img17, 
    id1img18, 
    id1img19, 
    id1img20, 
    id1img21, 
    id1img22, 
    id1img23, 
    id1img24, 
    id1img25, 
    id1img26, 
    id1img27, 
    id1img28, 
    id1img29, 
    id1img30
],
  },
 {
  id: 2,
  name: "Investor’s Deal | Exclusive | Near Lagoon",
  location: "Morocco by Damac, Damac Lagoons,Dubai",
  map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
  price: 3350000,
  distance: "1km",
  purpose: "Sale",
  dd_MM_yy: "",
  commercialsearch: "",
  puposesearch: 'Residential',
  number_of_beds: 5,
  bedsMaid:'Bedroom',
  maid:"+ Maid",
  number_of_bathrooms: 5,
  propertyType: "Villa",
  contact: "+971 50 2345678",
  dimensions: 3046,
  dealerImage: "",
  dealer: "Omar Al-Farsi",
  description: [
    {
      heading: "VILLA FOR SALE IN MOROCCO BY DAMAC, DAMAC LAGOONS",
      Paragraph:`
      *Seaboard Properties* is delighted to present this exclusive and stunning 5-bedroom + maid villa in the Morocco cluster of Damac Lagoons. This expansive property is perfect for both first-time buyers and seasoned investors, as well as those seeking a luxurious residence to call home.`
,
      content: ""
    },
 
    {
      heading: "Property Details",
      content: `- 5 Bedrooms + Maid's Room\n- 5 Bathrooms\n- Balcony\n- 2 Covered Parking Spaces\n- Walk-In Closet\n- Built-In Wardrobes\n- Expansive Living Area\n- Bright Dining Area\n- Modern Kitchen\n- Private Garden\n- Close to Park and Amenities`
  
    },
   
    {
      heading: "Key Highlights:",
   content: `- Largest lagoon across all phases\n- Private garden\n- Lush green landscapes\n- Natural mountains and caves\n- Fully fitted kitchens and washrooms\n- Built-in wardrobes\n- Outdoor spa\n- Floating cinema\n- Outdoor aqua library\n- Prime location\n- 24-hour security in a gated community\n- Proximity to international schools and healthcare centers`
    },
    {
      heading: "Location & Connectivity:",
       content: `- 8 minutes to Hessa Street\n- 10 minutes to Dubai Polo & Equestrian Club\n- 12 minutes to Dubai Autodrome\n- 13 minutes to EXPO 2020 site\n- 14 minutes to Dubai International Stadiums\n- 14 minutes to Global Village\n- 20 minutes to Mall of the Emirates\n- 28 minutes to Al Maktoum International Airport`
    },
    {
      heading: "",
      content: ``
    },
    {
      heading: "Contact Information",
      content: `For more details, please contact Seaboard Properties LLC at 050 323 1194 or 04-2359233.\n
      BRN No. 71148 | ORN No. 1903.`
    }
  ],
  amenities: [
    "Maids Room",
    "Test",
    "Central A/C",
    "Balcony",
    "Shared Pool",
    "Covered Parking",
    "Built-in Wardrobes",
    "Walk-in Closet",
    "View of Landmark",
    "Shared Gym",
    "Children's Pool",
    "Children's Play Area",
    "Barbecue Area"
  ],
  image : [ 
    id2img1, 
    id2img2, 
    id2img3, 
    id2img4, 
    id2img5, 
    id2img6, 
    id2img7, 
    id2img8, 
    id2img9, 
    id2img10, 
    id2img11, 
    id2img12, 
    id2img13, 
    id2img14, 
    id2img15, 
    id2img16, 
    id2img17, 
    id2img18, 
    id2img19, 
    id2img20, 
    id2img21, 
    id2img22, 
    id2img23, 
    id2img24, 
    id2img25, 
    id2img26, 
    id2img27, 
    id2img28, 
    id2img29, 

]
}
  ,
  {
    id: 3,
    name: "BEST INVESTMENT | PAYMENT PLAN | SPACIOUS",
    location: "Q Gardens Lofts 2, Jumeirah Village Circle,Dubai",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 987337,
    distance: "500m",
    purpose: "Sale",
    dd_MM_yy: "",

    puposesearch:'Residential',
    // number_of_beds: 2,
    commercialsearch:"",
    number_of_bathrooms: 1,
    propertyType: "Apartment",
    contact: "+971 50 3456789",
    dimensions: 547,
    dealerImage: "",
    // dd_MM_yy: "Year",
    bedsMaid:'Studio',
    dealer: "Sarah Ali",
 amenities : [
      "Central A/C",
      "Shared Pool",
      "Security",
      "Concierge",
      "Covered Parking",
      "Kitchen Appliances",
      "Shared Gym",
      "Lobby in Building",
      "Children's Pool",
      "Children's Play Area"
    ],
    
    description:[
      {
        heading: `APARTMENT FOR SALE IN Q GARDENS LOFTS 2, JUMEIRAH VILLAGE CIRCLE`,
        Paragraph: `A masterpiece of modern architecture, this development showcases elegant exteriors and tasteful interiors—a place you'll be proud to call home. Designed with the highest standards of modern living, it combines minimal chic design with luxurious amenities, offering residents an exceptional quality of life.`,
        content: ""
      },
      {
        heading: "Property Details",
        content: `- *5 mins to The Circle Mall\n- *10 mins to Dubai Butterfly Garden\n- *20 mins to Burj Khalifa & Mall of the Emirates\n- *25 mins to Dubai Marina\n- *30 mins to Dubai International Airport`
      },
      {
        heading: "Amenities:",
        content: `- *Indoor Fitness Areas\n- *Cinema Room\n- *Podium Parking\n- *Outdoor Sitting Area\n- *Parks & Leisure Areas\n- *Shops & Restaurants\n- *Rooftop Infinity Pool\n- *Kids Pool\n- *Gym & Fitness Studios\n- *BBQ Area`
      },
      {
        heading: "Key Features:",
        content: `- *Perfect Location\n- *European Branded Appliances\n- *Premium Amenities\n- *Modern Architecture\n- *Attractive Payment Plan`
      },

      {
        heading: "Contact Information:",
        content: `For more details, contact **Seaboard Properties LLC** at 050 323 1194 or 04-2359233.\n BRN No. 71148 | ORN No. 1903`
      }
    ],


    image : [ 
      id3img1, 
      id3img2, 
      id3img3, 
      id3img4, 
      id3img5, 
      id3img6, 
      id3img7, 
      id3img8, 
      id3img9, 
      id3img10, 
      id3img11, 
      id3img12, 
      id3img13, 
      id3img14, 
      id3img15, 
      id3img16, 
      id3img17, 
      id3img18, 
      id3img19, 
      id3img20, 
      id3img21, 
      id3img22, 
      id3img23, 
      id3img24, 
      id3img25, 
      id3img26, 
      id3img27, 
      id3img28, 
      id3img29,
      id3img30, 
  
  ]
  },
  {
    id: 4,
    name: "Fully Furnished | Bright and Spacious",
    location: "Regina Tower,Jumeirah Village Circle,Dubai",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 950000,
    distance: "0.5km",
    purpose: "Sale",
    dd_MM_yy: "",

    puposesearch:'Residential',
    number_of_beds: 1,
    number_of_bathrooms: 2,
    propertyType: "Apartment",
    contact: "+971 50 4567890",
    dimensions: 784,
    bedsMaid:'Bedroom',
    dealerImage: "",
    // dd_MM_yy: "Month",
    dealer: "Mohammed Rashid",
    description: [
      {
        heading: "APARTMENT FOR SALE IN REGINA TOWER, JUMEIRAH VILLAGE CIRCLE",
        Paragraph:`
        Seaboard Properties presents this impressive one bedroom apartment in Jumeirah Village Circle (JVC), with fabulous design influenced by Mediterranean architecture.`
,
        content: ""
      },
      {
        heading: "Property Details",
        content: `BUA: 784.47 sq. ft.\n
        Fully Furnished\n
        High Floor\n
        Classy one bedroom room\n
        Two bathrooms\n
        With Balcony\n
        Built-in Wardrobes\n
       Laundry/Storage Space\n
        Dedicated Parking\n
        - Roof Floor with Family Room and Bath\n
        - Balconies: 4 (Bedroom 2, Bedroom 3, Master Room, Roof Floor)\n
        - Flexible Payment Plan`
      },
      {
        heading: "Selling Price",
        content: `AED 950,000`
      },
      {
        heading: "Amenities",
        content: `Gymnasium\n
        Swimming Pool\n
        Health Club\n
        Covered Car Parking\n
        High Speed Elevators\n
        24 hours Security Equipped with CCTV\n
       Convenient Waste Disposable on Every Floor
        `
      },
      {
        heading: "ABOUT JVC:",
        content: `Jumeirah Village Circle (JVC) is one of the family-friendly master communities in Dubai. Located at the heart of new Dubai amidst landscaped gardens, it boasts a range of properties and amenities, making it ideal for renters and buyers. The community has maintained its position as the preferred option to rent or buy affordable apartments and Villa in Dubai.`
      },
   
      {
        heading: "Contact Information",
        content: `For more details, contact **Seaboard Properties LLC** at 050 323 1194 or 04-2359233.\n
       BRN No. 71148 | ORN No. 1903`
      }
    ],

 
  amenities: [
    "Central A/C",
    "Balcony",
    "Shared Pool",
    "Security",
    "Covered Parking",
    "Built-in Wardrobes",
    "Kitchen Appliances",
    "View of Landmark",
    "Shared Gym",
    "Lobby in Building",
    "Children's Pool",
    "Children's Play Area"
  ],
     image: [ 
      id4img1, 
      id4img2, 
      id4img3, 
      id4img4, 
      id4img5, 
      id4img6, 
      id4img7, 
      id4img8, 
      id4img9, 
      id4img10, 
      id4img11, 
      id4img12, 
      id4img13, 
      id4img14, 
      id4img15, 
      id4img16, 
      id4img17, 
      id4img18, 
      id4img19, 
      id4img20, 
      id4img21, 
      id4img22, 
      id4img23, 
      id4img24, 
      id4img25, 
      id4img26, 
      id4img27, 
      id4img28, 
      id4img29,
      id4img30, 

  
  ]
  },
  {
    id: 5,
    name: "Great Investment Opportunity | Spacious Unit | Currently Vacant",
    location: "AL GHOZLAN 4, AL GHOZLAN,Dubai",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 970000,
    distance: "1.5km",
    purpose: "Sale",
    dd_MM_yy: "",

    puposesearch:'Residential',
    // commercialsearch:"CommercialRent",
    // number_of_beds: 0,
    number_of_bathrooms: 1,
    propertyType: "Apartment",
    contact: "+971 50 5678901",
    // dd_MM_yy: "Day",
    bedsMaid:'Studio',
    dimensions: 490,
    dealerImage: "",
    description: [
      {
        heading: "APARTMENT FOR SALE IN AL GHOZLAN 4, AL GHOZLAN",
        Paragraph:`
        Seaboard Properties presents an impressive studio apartment in Al Ghozlan 4, The Greens.
`
,
        content: ""
      },
      {
        heading: "Property Details",
        content: `- • BUA: 490 sq. ft.\n
        • Classy Studio Apartment\n
        • Two Bathrooms\n
        • Balcony\n
        • Built-in Wardrobes\n
        • Laundry/Storage Space\n
        • Dedicated Parking`
      },
    
      {
        heading: "BUILDING AMENITIES:",
        content: `• Gym and Pool\n
        • Mosque\n
        • Public Transport\n
        • Public Park\n
        • Communal Gardens\n
        • Children's Play Area\n
        • Metro Station\n
        • Public Parking\n
        • Retail Shops\n
        • Security Services`
      },
      {
        heading: "Al Ghozlan 4 Highlights:",
        content: `Located in The Greens, Al Ghozlan 4 is a low-rise Residential building, the fourth in the Al Ghozlan complex. It offers studios, 1-bedroom, and 2-bedroom apartments. The building features several amenities, including a gym, swimming pool, BBQ area, multipurpose hall, and children's play area. The complex, located on the 4th and 5th streets of The Greens, comprises four buildings with a total of 452 Residential units. It’s an ideal location for those seeking a peaceful environment with all the conveniences of urban living`
      },
    
      {
        heading: "Contact Information",
        content: `For more details, please contact Seaboard Properties LLC at 050 323 1194 or 04-2359233.\n
        BRN No. 71148 | ORN No. 1903`
      }
    ],


  amenities: [
    "Central A/C",
    "Balcony",
    "Shared Pool",
    "Built-in Wardrobes",
    "View of Landmark",
    "Shared Gym",
    "Lobby in Building"
  ],
    dealer: "Fatima Al-Mansoori",

     image: [
      id7img1,
      id7img2,
      id7img3,
      id7img4,
      id7img5,
      id7img6,
      id7img7,
      id7img8,
      id7img9,
      id7img10,
      id7img11,

  ]
  },
  {
    id: 6,
    name: "Stunning | Quality Living | Bright and Spacious",
    location: "GOLDCREST EXECUTIVE, JLT CLUSTER C,Dubai",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 880000,
    distance: "3km",
    purpose: "Sale",
    dd_MM_yy: "",

    puposesearch:'Residential',
    // number_of_beds: 4,
    number_of_bathrooms: 1,
    // commercialsearch:"Resid",
    propertyType: "Apartment",
    contact: "+971 50 6789012",
    dimensions: 375,
    dealerImage: "",
    bedsMaid:"Studio",
    // dd_MM_yy: "Week",
    dealer: "Noura Al-Sheikh",
    description: [
      {
        heading: "APARTMENT FOR SALE IN GOLDCREST EXECUTIVE, JLT CLUSTER C",
        Paragraph:`
        Seaboard Properties presents this impressive studio apartment in Jumeirah Lake Tower (JLT), with fabulous design influenced by Mediterranean architecture.`
,
        content: ""
      },
      {
        heading: "Property Details",
        content: `Studio Apartment\n
        BUA: 375 sqft\n
        Well maintained\n
        Dedicated Parking`
      },
      {
        heading: "Selling Price",
        content: `AED 880,000.00
`
      },
      {
        heading: "Amenities",
        content: `Gymnasium\n
        Swimming Pool\n
        Covered Car Parking\n
        High Speed Elevators\n
        24 hours Security Equipped with CCTV\n
        Convenient Waste Disposable on Every Floor`
      },
      {
        heading: "ABOUT JLT:",
        content: `Jumeirah Lakes Towers or JLT as it is commonly known has a sense of community within JLT for families, couples, and singles made possible by the walking and jogging tracks around the lakes, play areas for children, basketball courts, restaurants, and other retail amenities built in and around the Residential buildings that include dry cleaners, beauty parlors, supermarkets, shops, and restaurants.`
      },

      {
        heading: "Contact Information",
        content: `*For more details, please contact Seaboard Properties LLC at 050 323 1194 or 04-2359233.*\n
       **BRN No. 71148 | ORN No. 1903**`
      }
    ],

  
  //   description3:`Investing in Farm Gardens at The Valley with Seaboard Properties combines real estate expertise with the rising demand for sustainable agriculture, offering a smart and eco-friendly investment opportunity.`,
  //   description4:`**PROPERTY DETAILS:**`,
  //   description5:`- Corner Large Plot`,
  //   description6:`- Community: Farm Gardens, The Valley, Al Ain Road`,
  //   description7:`- Size: 9,808 sq. ft.`,
  //   description8:`- Bedrooms: 4 + Maid's Room with Ensuite`,
  //   description9:`- Bathrooms: 5`,
  //   description10:`- Garage`,
  // description11:`- Laundry Room`,
  // description12:`- Lift`,
  // description13:`- Private Pool`,
  // description14:`- Roof Floor with Family Room and Bath`,
  // description15:`- Balconies: 4 (Bedroom 2, Bedroom 3, Master Room, Roof Floor)`,
  // description16:`- Flexible Payment Plan`,
  // description17:`**Selling Price:** AED 6,500,000`,
  // description18:`**AMENITIES/SERVICES:**`,
  // description19:`- Grand Lawn`,
  // description20:`- Petting Zoo & Animal Farm`,
  // description21:` - Kids Play Area`,
  // description22:`- Hydroponics`,
  // description23:`- Community Farming`,
  // description24:`- Desert Majlis & Bonfire`,
  // description25:`- Stargazing Platforms`,
  // description26:`- Picnic Spots`,
  // description27:` - Arrival Plaza`,
  // description29:`- Outdoor Fitness Station`,
  // description30:`- Yoga/Events Lawn`,
  // description31:`- Xeriscape Botanical Garden`,
  // description32:`- Events Plaza`,
  // description33:`- Pool Deck`,
  // description34:`- Padel Court`,
  // description35:`- Volleyball`,
  // description36:`- Ghaf Forest`,
  // description37:` **LOCATIONS/NEAR ATTRACTIONS:**`,
  // description39:`- 5 mins to Rugby Sevens Stadium`,
  // description40:`- 8 mins from Dubai Outlet Mall`,
  // description41:`- 25 mins from Burj Khalifa & Downtown Dubai`,
  // description42:`- 25 mins from Dubai International Airport`,
  // description43:`Farm Gardens at The Valley by Emaar is a peaceful community in Dubai featuring luxurious farmhouse-style Villa. Surrounded by lush greenery and agricultural spaces, it offers a perfect blend of modern living and rustic, countryside charm with top-notch amenities for a relaxed lifestyle.`,
  // description44:`
  //       For more details, please contact Seaboard Properties LLC at 050 323 1194 or 04-2359233.
  //       BRN No. 71148 | ORN No. 1903.`,
  amenities: [
   "Central A/C",
  "Balcony",
  "Shared Pool",
  "Security",
  "Covered Parking",
  "Built-in Wardrobes",
  "View of Water",
  "View of Landmark",
  "Shared Gym",
  "Lobby in Building",
  "Children's Pool",
  "Children's Play Area"
  ],
     image: [
      id5img1, 
      id5img2, 
      id5img3, 
      id5img4, 
      id5img5, 
      id5img6, 
      id5img7, 
      id5img8, 
  



  ]
  },
  {
    id: 7,
    name: "Serene Community| Exquisite Living| Luxury",
    location: "MOROCCO BY DAMAC, DAMAC LAGOONS, Dubai",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 3500000,
    distance: "20km",
    purpose: "Sale",
    puposesearch:'Residential',
    // commercialsearch:"CommercialRent",
    number_of_beds: 5,
    number_of_bathrooms: 6 ,
    dd_MM_yy: "",
    propertyType: "Apartment",
    contact: "+971 50 7890123",
    dimensions: 3047,
    bedsMaid:'Bedroom',
  maid:"+ Maid",
    dealerImage: "",
    // dd_MM_yy: "Month",
    dealer: "Hassan Al-Harbi",
    description: [
      {
        heading: "VILLA FOR SALE IN MOROCCO BY DAMAC, DAMAC LAGOONS",
        Paragraph:`
        Morocco Lagoons offers standalone Villa designed with Moroccan-inspired architecture and decor. Immerse yourself in nature’s beauty, enjoying endless possibilities for fun and relaxation. Experience vibrant living with a perfect balance of tranquility and energy, creating a happy home for you and your loved ones.`
,
        content: ""
      },
      {
        heading: "Property Details",
        content: `Surrounded by stunning landscapes, Morocco Lagoons offers breathtaking views of azure lagoons and lush greenery. With resort-style amenities that include serene gardens, beautiful water features, and a fully-equipped fitness center, residents can enjoy a perfect balance of relaxation and activity. The waterfront living experience is ideal for those who love snorkeling, ziplining, or practicing yoga. Additionally, a boutique retail area provides convenient shopping and indulgence just steps away. Located near the Dubai Polo Club, Autodrome, and Expo 2020, this development offers both luxury and convenience in one prime location.
`
      },
      
      {
        heading: "Location Highlights:",
        content: `• 10 Minutes – Dubai Polo & Equestrian Club\n
        • 12 Minutes – Dubai Autodrome\n
        • 13 Minutes – EXPO 2020\n
        • 14 Minutes – Dubai International Stadium\n
        • 14 Minutes – Global Village\n
        • 24 Minutes – Mall of the Emirates\n
        • 28 Minutes – Al Maktoum International Airport\n
        `
      },
      {
        heading: "Facilities and Amenities:",
        content: `• Amusement park\n
        • Swimming pool\n
        • Fully-equipped gym\n
        • Verdant green surroundings\n
        • Variety of restaurants\n
        • Parks and leisure areas\n
        • Dining outlets\n
        • Health care center\n
        • Ample parking\n
        • Retail outlets\n
        • Retail outlets\n
• Kids play area\n
• Tennis courts
        `
      },
      {
        heading: "Contact Information",
        content: `For more details, please contact Seaboard Properties LLC at 050 323 1194 or 04-2359233.\n
        BRN No. 71148 | ORN No. 1903`
      }
    ],

 
  //   description3:`Investing in Farm Gardens at The Valley with Seaboard Properties combines real estate expertise with the rising demand for sustainable agriculture, offering a smart and eco-friendly investment opportunity.`,
  //   description4:`**PROPERTY DETAILS:**`,
  //   description5:`- Corner Large Plot`,
  //   description6:`- Community: Farm Gardens, The Valley, Al Ain Road`,
  //   description7:`- Size: 9,808 sq. ft.`,
  //   description8:`- Bedrooms: 4 + Maid's Room with Ensuite`,
  //   description9:`- Bathrooms: 5`,
  //   description10:`- Garage`,
  // description11:`- Laundry Room`,
  // description12:`- Lift`,
  // description13:`- Private Pool`,
  // description14:`- Roof Floor with Family Room and Bath`,
  // description15:`- Balconies: 4 (Bedroom 2, Bedroom 3, Master Room, Roof Floor)`,
  // description16:`- Flexible Payment Plan`,
  // description17:`**Selling Price:** AED 6,500,000`,
  // description18:`**AMENITIES/SERVICES:**`,
  // description19:`- Grand Lawn`,
  // description20:`- Petting Zoo & Animal Farm`,
  // description21:` - Kids Play Area`,
  // description22:`- Hydroponics`,
  // description23:`- Community Farming`,
  // description24:`- Desert Majlis & Bonfire`,
  // description25:`- Stargazing Platforms`,
  // description26:`- Picnic Spots`,
  // description27:` - Arrival Plaza`,
  // description29:`- Outdoor Fitness Station`,
  // description30:`- Yoga/Events Lawn`,
  // description31:`- Xeriscape Botanical Garden`,
  // description32:`- Events Plaza`,
  // description33:`- Pool Deck`,
  // description34:`- Padel Court`,
  // description35:`- Volleyball`,
  // description36:`- Ghaf Forest`,
  // description37:` **LOCATIONS/NEAR ATTRACTIONS:**`,
  // description39:`- 5 mins to Rugby Sevens Stadium`,
  // description40:`- 8 mins from Dubai Outlet Mall`,
  // description41:`- 25 mins from Burj Khalifa & Downtown Dubai`,
  // description42:`- 25 mins from Dubai International Airport`,
  // description43:`Farm Gardens at The Valley by Emaar is a peaceful community in Dubai featuring luxurious farmhouse-style Villa. Surrounded by lush greenery and agricultural spaces, it offers a perfect blend of modern living and rustic, countryside charm with top-notch amenities for a relaxed lifestyle.`,
  // description44:`
  //       For more details, please contact Seaboard Properties LLC at 050 323 1194 or 04-2359233.
  //       BRN No. 71148 | ORN No. 1903.`,
  amenities: [
    "- Grand Lawn",
    "- Petting Zoo & Animal Farm",
    "- Kids Play Area",
    "- Hydroponics",
    "- Community Farming",
    "- Desert Majlis & Bonfire",
    "- Stargazing Platforms",
    "- Picnic Spots",
    "- Arrival Plaza",
    "- Outdoor Fitness Station",
    "- Yoga/Events Lawn",
    "- Xeriscape Botanical Garden",
    "- Events Plaza",
    "- Pool Deck",
    "- Padel Court",
    "- Volleyball",
    "- Ghaf Forest"
  ],
     image: [
       id2img3, 
       id2img2, 
       id2img1, 
      id2img4, 
      id2img5, 
      id2img6, 
      id2img7, 
      id2img8, 
      id2img9, 
      id2img10, 
      id2img11, 
      id2img12, 
      id2img13, 
      id2img14, 
      id2img15, 
      id2img16, 
      id2img17, 
      id2img18, 
      id2img19, 
      id2img20, 
      id2img21, 
      id2img22, 
      id2img23, 
      id2img24, 
      id2img25, 
      id2img26, 
      id2img27, 
      id2img28, 
      id2img29,

  ]
  },
  {
    id: 8,
    name: "LUXURY HOME|HIGH ROI|PAYMENT PLAN",
    location: "MEYDAN, Dubai",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 2480800,
    distance: "2km",
    purpose: "Sale",
    puposesearch:'Residential',
    // commercialsearch:"CommercialSale",
    number_of_beds: 1,
    number_of_bathrooms: 2,
    dd_MM_yy: "",
    propertyType: "Apartment",
    bedsMaid:'Bedroom',

    contact: "+971 50 8901234",
    dimensions: 1001,
    dealerImage: "",
    // dd_MM_yy: "Year",
    dealer: "Layla Jassim",
    description: [
      {
        heading: "Villa for sale in Farm Gardens, The Valley",
        Paragraph:`
        Seaboard Properties is excited to present this luxurious apartment in Tonino Lamborghini Residences, where exceptional design meets cutting-edge smart technology for seamless living.
`
,
        content: ""
      },
      {
        heading: "Property Details",
        content: `1 Bed: 790 - 1,200 sq.ft\n
        2 Beds: 1,200 - 2,870 sq.ft\n
        3 Beds: 1,500 - 2,280 sq.ft\n
        4 Beds: 2,454 - 4,708 sq.ft`
      },
      
      {
        heading: "**EXCLUSIVE FEATURES:**\nWith over 15 premium amenities, enjoy:",
        content: `* Smart Home Technology\n
        * Infinity Pool with stunning views\n
        * Paddle Tennis Court\n
        * Clubhouse\n
        * Steam & Sauna\n
        * Supermarket\n
        * Branded Fitness Center\n
        * Open Cinema & Gaming Room\n
        * Children's Play Area\n
        * Landscaped Gardens\n
        * 24/7 Concierge Service\n
        * Jogging Track & Indoor Cycling Studio\n
        * Restaurants & Cafes\n
        * And much more!\n
        `
      },
      {
        heading: "",
        content: `Additionally, the property offers state-of-the-art gym facilities, luxurious spa services, serene swimming pools, 24-hour security, covered parking, and exclusive retail shops.
`
      },
      {
        heading: "",
        content: `The Tonino Lamborghini Residences epitomize luxury, merging Italian craftsmanship with modern elegance. Strategically located, these residences are designed to reflect the signature Lamborghini style, providing a perfect fusion of sophistication and innovation for the discerning resident.
`
      },
      {
        heading: "Contact Information",
        content: `For more details, contact **Seaboard Properties LLC** at 050 323 1194 or 04-2359233.\n
        BRN No. 71148 | ORN No. 1903  .`
      }
    ],

  
  //   description3:`Investing in Farm Gardens at The Valley with Seaboard Properties combines real estate expertise with the rising demand for sustainable agriculture, offering a smart and eco-friendly investment opportunity.`,
  //   description4:`**PROPERTY DETAILS:**`,
  //   description5:`- Corner Large Plot`,
  //   description6:`- Community: Farm Gardens, The Valley, Al Ain Road`,
  //   description7:`- Size: 9,808 sq. ft.`,
  //   description8:`- Bedrooms: 4 + Maid's Room with Ensuite`,
  //   description9:`- Bathrooms: 5`,
  //   description10:`- Garage`,
  // description11:`- Laundry Room`,
  // description12:`- Lift`,
  // description13:`- Private Pool`,
  // description14:`- Roof Floor with Family Room and Bath`,
  // description15:`- Balconies: 4 (Bedroom 2, Bedroom 3, Master Room, Roof Floor)`,
  // description16:`- Flexible Payment Plan`,
  // description17:`**Selling Price:** AED 6,500,000`,
  // description18:`**AMENITIES/SERVICES:**`,
  // description19:`- Grand Lawn`,
  // description20:`- Petting Zoo & Animal Farm`,
  // description21:` - Kids Play Area`,
  // description22:`- Hydroponics`,
  // description23:`- Community Farming`,
  // description24:`- Desert Majlis & Bonfire`,
  // description25:`- Stargazing Platforms`,
  // description26:`- Picnic Spots`,
  // description27:` - Arrival Plaza`,
  // description29:`- Outdoor Fitness Station`,
  // description30:`- Yoga/Events Lawn`,
  // description31:`- Xeriscape Botanical Garden`,
  // description32:`- Events Plaza`,
  // description33:`- Pool Deck`,
  // description34:`- Padel Court`,
  // description35:`- Volleyball`,
  // description36:`- Ghaf Forest`,
  // description37:` **LOCATIONS/NEAR ATTRACTIONS:**`,
  // description39:`- 5 mins to Rugby Sevens Stadium`,
  // description40:`- 8 mins from Dubai Outlet Mall`,
  // description41:`- 25 mins from Burj Khalifa & Downtown Dubai`,
  // description42:`- 25 mins from Dubai International Airport`,
  // description43:`Farm Gardens at The Valley by Emaar is a peaceful community in Dubai featuring luxurious farmhouse-style Villa. Surrounded by lush greenery and agricultural spaces, it offers a perfect blend of modern living and rustic, countryside charm with top-notch amenities for a relaxed lifestyle.`,
  // description44:`
  //       For more details, please contact Seaboard Properties LLC at 050 323 1194 or 04-2359233.
  //       BRN No. 71148 | ORN No. 1903.`,
   amenities : [
    "Central A/C",
    "Balcony",
    "Shared Pool",
    "Security",
    "Concierge",
    "Covered Parking",
    "Built-in Wardrobes",
    "View of Landmark",
    "Shared Gym",
    "Lobby in Building",
    "Children's Pool",
    "Children's Play Area"
],

     image: [
      id8img1, 
      id8img2, 
      id8img3, 
      id8img4, 
      id8img5, 
      id8img6, 
      id8img7, 
      id8img8, 
      id8img9, 
      id8img10, 
      id8img11, 
      id8img12, 
      id8img13, 
      id8img14, 
      id8img15,
  ]
  },
  {
    id: 9,
    name: "Burj View| Fully Furnished |Prime Location",
    location: "Dubai, Downtown Dubai, The Address Sky View",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 60000,
    distance: "1km",
    purpose: "Rent",
    // number_of_beds: 1,
    bedsMaid:"Studio",
    number_of_bathrooms: 1,
    puposesearch:'Residential',
    // commercialsearch:"CommercialRent",
    propertyType: "Apartment",
    contact: "+971 50 9012345",
    dimensions: 407,
    dealerImage: "",
    dd_MM_yy: "Year",
    dealer: "Ahmed Faris",
    description: [
      {
        heading: "Villa for sale in Farm Gardens, The Valley",
        Paragraph:`
        Seaboard Properties presents this impressive studio apartment in a new Residential building in Business Bay, featuring a fabulous design influenced by modern architecture.
`
,
        content: ""
      },
      {
        heading: "Property Details",
        content: ` Higher Floor\n
        - Fully Equipped Kitchen\n
        - Luxurious Modern Style\n
        - Built-in Closet\n
        - Huge Balcony\n
        - Spacious Living Room\n
        - Burj Khalifa and City View\n
        - Amazing Night View\n
        - Covered Parking`
      },
      {
        heading: "Selling Price",
        content: `AED 60,000.00`
      },
      {
        heading: "Amenities",
        content: `- Health Club\n
        - Fully Equipped Modern Gym\n
        - Sauna and Steam Rooms\n
        - Barbecue Area\n
        - Cinema Room\n
        - Event Space\n
        - Covered Parking\n
        - Swimming Pool\n
       `
      },
      {
        heading: "",
        content: `Vacant and Available on September 15, 2024.`
      },
      {
        heading: "",
        content: `The number of cheques and rent can be negotiated depending on the offer.`
      },
      {
        heading: "Contact Information",
        content: `For more details, please contact Seaboard Properties LLC at 050 323 1194 or 04-2359233.\n
        **BRN No. 71148 | ORN No. 1903**`
      }
    ],

 
  //   description3:`Investing in Farm Gardens at The Valley with Seaboard Properties combines real estate expertise with the rising demand for sustainable agriculture, offering a smart and eco-friendly investment opportunity.`,
  //   description4:`**PROPERTY DETAILS:**`,
  //   description5:`- Corner Large Plot`,
  //   description6:`- Community: Farm Gardens, The Valley, Al Ain Road`,
  //   description7:`- Size: 9,808 sq. ft.`,
  //   description8:`- Bedrooms: 4 + Maid's Room with Ensuite`,
  //   description9:`- Bathrooms: 5`,
  //   description10:`- Garage`,
  // description11:`- Laundry Room`,
  // description12:`- Lift`,
  // description13:`- Private Pool`,
  // description14:`- Roof Floor with Family Room and Bath`,
  // description15:`- Balconies: 4 (Bedroom 2, Bedroom 3, Master Room, Roof Floor)`,
  // description16:`- Flexible Payment Plan`,
  // description17:`**Selling Price:** AED 6,500,000`,
  // description18:`**AMENITIES/SERVICES:**`,
  // description19:`- Grand Lawn`,
  // description20:`- Petting Zoo & Animal Farm`,
  // description21:` - Kids Play Area`,
  // description22:`- Hydroponics`,
  // description23:`- Community Farming`,
  // description24:`- Desert Majlis & Bonfire`,
  // description25:`- Stargazing Platforms`,
  // description26:`- Picnic Spots`,
  // description27:` - Arrival Plaza`,
  // description29:`- Outdoor Fitness Station`,
  // description30:`- Yoga/Events Lawn`,
  // description31:`- Xeriscape Botanical Garden`,
  // description32:`- Events Plaza`,
  // description33:`- Pool Deck`,
  // description34:`- Padel Court`,
  // description35:`- Volleyball`,
  // description36:`- Ghaf Forest`,
  // description37:` **LOCATIONS/NEAR ATTRACTIONS:**`,
  // description39:`- 5 mins to Rugby Sevens Stadium`,
  // description40:`- 8 mins from Dubai Outlet Mall`,
  // description41:`- 25 mins from Burj Khalifa & Downtown Dubai`,
  // description42:`- 25 mins from Dubai International Airport`,
  // description43:`Farm Gardens at The Valley by Emaar is a peaceful community in Dubai featuring luxurious farmhouse-style Villa. Surrounded by lush greenery and agricultural spaces, it offers a perfect blend of modern living and rustic, countryside charm with top-notch amenities for a relaxed lifestyle.`,
  // description44:`
  //       For more details, please contact Seaboard Properties LLC at 050 323 1194 or 04-2359233.
  //       BRN No. 71148 | ORN No. 1903.`,
  amenities: [
   "Central A/C",
    "Balcony",
    "Shared Pool",
    "Security",
    "Covered Parking",
    "Built-in Wardrobes",
    "Kitchen Appliances",
    "Shared Gym",
    "Lobby in Building",
    "Children's Pool",
    "Children's Play Area"
  ],
     image: [
      id9img1, 
      id9img2, 
      id9img3, 
      id9img4, 
      id9img5, 
      id9img6, 
      id9img7, 
      id9img8, 
      id9img9, 
      id9img10, 
      id9img11, 
      id9img12, 
      id9img13, 
      id9img14, 
      id9img15,
  ]
  },
  {
    id: 10,
    name: "Fully Furnished| Quality Living| Bright and Spacious",
    location: "Dubai, Palm Jumeirah, Frond N",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 85000 ,
    distance: "3km",
    purpose: "Rent",
    dd_MM_yy: "Year",
    number_of_beds: 1,
    number_of_bathrooms: 2,
    propertyType: "Apartment",
    puposesearch:'Residential',
    // commercialsearch:"CommercialSale",
    contact: "+971 50 0123456",
 bedsMaid:"Bedroom",
 
    dimensions: 784,
    dealerImage: "",

    dealer: "Rashid Al-Mutairi",
    description: [
      {
        heading: "Apartment for rent in Regina Tower, Jumeirah Village Circle",
        Paragraph:`
        Seaboard Properties presents this impressive one bedroom apartment in Jumeirah Village Circle (JVC), with fabulous design influenced by Mediterranean architecture.`
,
        content: ""
      },
      {
        heading: "Property Details",
        content: `BUA: 784.47 sq. ft.\n
        Fully Furnished\n
        High Floor\n
        Classy one bedroom roomn
        Two bathrooms\n
        With Balcony\n
        Built-in Wardrobes\n
        Laundry/Storage Space\n
        Dedicated Parking`
      },
      {
        heading: "Selling Price",
        content: `AED 85,000.00`
      },
      {
        heading: "Amenities",
        content: `Gymnasium\n
        Swimming Pool\n
        Health Club\n
        Covered Car Parking\n
        High Speed Elevators\n
        24 hours Security Equipped with CCTV\n
        Convenient Waste Disposable on Every Floor\n
        `
      },
      {
        heading: "ABOUT JVC:",
        content: `Jumeirah Village Circle (JVC) is one of the family-friendly master communities in Dubai. Located at the heart of new Dubai amidst landscaped gardens, it boasts a range of properties and amenities, making it ideal for renters and buyers. The community has maintained its position as the preferred option to rent or buy affordable apartments and Villa in Dubai.
`
      },

      {
        heading: "Contact Information",
        content: `For more details, contact **Seaboard Properties LLC** at 050 323 1194 or 04-2359233.\n
        BRN No. 71148 | ORN No. 1903`
      }
    ],

 
  //   description3:`Investing in Farm Gardens at The Valley with Seaboard Properties combines real estate expertise with the rising demand for sustainable agriculture, offering a smart and eco-friendly investment opportunity.`,
  //   description4:`**PROPERTY DETAILS:**`,
  //   description5:`- Corner Large Plot`,
  //   description6:`- Community: Farm Gardens, The Valley, Al Ain Road`,
  //   description7:`- Size: 9,808 sq. ft.`,
  //   description8:`- Bedrooms: 4 + Maid's Room with Ensuite`,
  //   description9:`- Bathrooms: 5`,
  //   description10:`- Garage`,
  // description11:`- Laundry Room`,
  // description12:`- Lift`,
  // description13:`- Private Pool`,
  // description14:`- Roof Floor with Family Room and Bath`,
  // description15:`- Balconies: 4 (Bedroom 2, Bedroom 3, Master Room, Roof Floor)`,
  // description16:`- Flexible Payment Plan`,
  // description17:`**Selling Price:** AED 6,500,000`,
  // description18:`**AMENITIES/SERVICES:**`,
  // description19:`- Grand Lawn`,
  // description20:`- Petting Zoo & Animal Farm`,
  // description21:` - Kids Play Area`,
  // description22:`- Hydroponics`,
  // description23:`- Community Farming`,
  // description24:`- Desert Majlis & Bonfire`,
  // description25:`- Stargazing Platforms`,
  // description26:`- Picnic Spots`,
  // description27:` - Arrival Plaza`,
  // description29:`- Outdoor Fitness Station`,
  // description30:`- Yoga/Events Lawn`,
  // description31:`- Xeriscape Botanical Garden`,
  // description32:`- Events Plaza`,
  // description33:`- Pool Deck`,
  // description34:`- Padel Court`,
  // description35:`- Volleyball`,
  // description36:`- Ghaf Forest`,
  // description37:` **LOCATIONS/NEAR ATTRACTIONS:**`,
  // description39:`- 5 mins to Rugby Sevens Stadium`,
  // description40:`- 8 mins from Dubai Outlet Mall`,
  // description41:`- 25 mins from Burj Khalifa & Downtown Dubai`,
  // description42:`- 25 mins from Dubai International Airport`,
  // description43:`Farm Gardens at The Valley by Emaar is a peaceful community in Dubai featuring luxurious farmhouse-style Villa. Surrounded by lush greenery and agricultural spaces, it offers a perfect blend of modern living and rustic, countryside charm with top-notch amenities for a relaxed lifestyle.`,
  // description44:`
  //       For more details, please contact Seaboard Properties LLC at 050 323 1194 or 04-2359233.
  //       BRN No. 71148 | ORN No. 1903.`,
  amenities: [
    "Central A/C",
    "Balcony",
    "Shared Pool",
    "Security",
    "Covered Parking",
    "Built-in Wardrobes",
    "Kitchen Appliances",
    "View of Landmark",
    "Shared Gym",
    "Lobby in Building",
    "Children's Pool",
    "Children's Play Area"
  ],
     image: [
      id10img3, 
      id10img2, 
      id10img1, 
     id10img4, 
     id10img5, 
     id10img6, 
     id10img7, 
     id10img8, 
     id10img9, 
     id10img10, 
     id10img11, 
     id10img12, 
     id10img13, 
     id10img14, 
     id10img15, 
     id10img16, 
     id10img17, 
     id10img18, 
     id10img19, 
     id10img20, 
     id10img21, 
     id10img22, 
     id10img23, 
     id10img24, 
     id10img25, 
     id10img26, 
     id10img27, 
     id10img28, 
     id10img29,
     id10img30,
  ]
  }
];

export const testimonials = [
  {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  },
  {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "The website is sleek and organized, helping me narrow down choices by area budget. Customer support was friendly and knowledgeable, making the entire process enjoyable. Highly recommend for anyone looking to settle in Dubai!",
  },
  {
    id: 2,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I’ve used several property websites in Dubai, It’s so easy to browse by neighborhood, and the listings are up-to-date with real photos. I found a stunning apartment that matched my needs perfectly.",
  },
  {
    id: 3,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Their team went the extra mile to explain the rental process here, and the website made it simple to compare listings. I found a property within days and felt supported the entire time. Couldn’t have asked for a better experience!",
  },
  {
    id: 4,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was specifically looking for properties near the business district, and their site made it easy to filter by location. The listings are detailed, with great photos and clear pricing. It’s a professional service, and I’m happy to recommend them to anyone looking for property in Dubai.",
  },
  {
    id: 5,
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.0}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "I was especially impressed by their team, who were always quick to respond and knowledgeable about the Dubai property market. I’ll definitely be coming back for my future needs.",
  },
  {
    id: 6, // Changed id to avoid duplicates
    name: "Wabz Braize",
    role: (
      <ReactStars
        size={18}
        isHalf={true}
        activeColor="#ffd700"
        value={4.5}
        edit={false}
      />
    ),
    image: "/images/avatar.png",
    reviewText:
      "Seaboard Properties offers a fantastic range of Dubai listings, from family villas to chic apartments. I found a beautiful place within my budget, and the team even helped me negotiate a great deal. For anyone searching in Dubai, this site is a must!",
  },
];

export const feeds = [
  {
    id: 1,
    title: "Dubai Metro Line Extension: Transforming the City’s Connectivity and Property Market.",
    date_posted: "january 29, 2023",
    image: casino,
    // category: "architecture",
    sections: [
      {
        heading: "Introduction",
        content: `Dubai, renowned for its innovative urban development, is continually enhancing its public transport systems. 
        The Dubai Metro Line Extension, especially along Route 2020, is pivotal for connectivity and transforming the real estate landscape, 
        creating new opportunities for investment and property development.`
      },
      {
        heading: "The Route 2020 Metro Line Extension",
        content: `The Dubai Metro, one of the longest driverless metro systems globally, is essential for residents and tourists. 
        The Route 2020 extension, launched before Expo 2020, stretches from Nakheel Harbour & Tower station on the Red Line to the Expo site. 
        This extension is a vital part of Dubai’s urban planning, linking emerging districts to major hubs.`,
        newStations: [
          "Jebel Ali",
          "The Gardens",
          "Discovery Gardens",
          "Al Furjan",
          "Jumeirah Golf Estates",
          "Dubai Investment Park (DIP)",
          "Expo 2020"
        ]
      },
      {
        heading: "Impact on the Property Market",
        content: `The Metro Line extension is catalyzing property development in its service areas, enhancing real estate activity. 
        Here’s how it’s transforming the market:`,
        points: [
          {
            title: "Increased Property Value",
            description: `Proximity to public transport boosts property value. The new metro stations are expected to raise property prices 
            and rental yields in areas like Al Furjan, Dubai Investment Park, and Discovery Gardens.`
          },
          {
            title: "Rise in Demand for Rental Properties",
            description: `Expatriates and young professionals prefer living near public transport to avoid car ownership costs. 
            This demand is increasing for rental properties in newly connected areas like Jumeirah Golf Estates and Dubai Investment Park.`
          },
          {
            title: "Development of New Residential Projects",
            description: `New Residential developments are planned along the metro extension, focusing on integrated communities 
            with Residential, commercial, and leisure spaces within walking distance of metro stations.`
          },
          {
            title: "Boost to Commercial Real Estate",
            description: `Improved transportation links are attracting businesses to expand in areas like Jebel Ali and Dubai South, 
            turning them into key business hubs with increased foot traffic and visibility.`
          }
        ]
      },
      {
        heading: "Enhanced Lifestyle and Community Development",
        content: `The Metro extension is improving the lifestyle of residents in surrounding communities. 
        Shorter commutes and reduced congestion lead to healthier environments. Areas like Jumeirah Golf Estates and Al Furjan are 
        becoming self-sustained neighborhoods with modern amenities, parks, schools, and retail spaces.`
      },
      {
        heading: "Long-Term Vision: Connecting to Dubai’s Future",
        content: `The Metro Line extension aligns with Dubai’s long-term vision for infrastructure and urban development. 
        As the city grows, integrating public transport with Residential and commercial areas becomes crucial. 
        The Route 2020 extension is one of many projects making Dubai more connected and livable.`
      },
      {
        heading: "Conclusion",
        content: `The Dubai Metro Line extension is more than a transport upgrade; it’s a significant advancement in urban planning reshaping 
        the property landscape. Whether you're a homebuyer, tenant, or investor, the areas around the Route 2020 extension are prime 
        opportunities to watch. With enhanced connectivity and community growth, the future of Dubai’s property market looks promising.`
      }
    ],   author: {
      name: "Wabweni Brian",
      avatar: "/images/avatar.png",
    },
  },
  {
    id: 2,
    title: "Dubai's New Casino Island on Marjan: A Game-Changer for Tourism and Real Estate.",
    date_posted: "January 29, 2023",
    image: newcasino,
    sections: [
      {
        heading: "The Rise of Casino Island",
        content: `Al Marjan Island, a man-made archipelago in Ras Al Khaimah, is known for its pristine beaches and luxury resorts. The addition of a casino resort, spearheaded by Wynn Resorts, promises to rival global entertainment destinations like Las Vegas and Macau. This ambitious project will include a world-class casino, luxury hotel accommodations, entertainment venues, and top-tier dining and shopping experiences.`
      },
      {
        heading: "What the Casino Island Means for Dubai's Property Market",
        content: `As the UAE opens up to the global gaming and entertainment sector, the Casino Island is expected to have a significant ripple effect on the property market. Here’s how:`,
        points: [
          {
            title: "Boost in Real Estate Demand",
            description: `The development is expected to attract a large influx of tourists and expatriates, driving demand for luxury Villa, beachfront properties, and premium Apartments near the casino hub.`
          },
          {
            title: "Growth of Hospitality and Commercial Properties",
            description: `New hotels, resorts, restaurants, and entertainment centers will likely emerge in the vicinity, creating lucrative investment opportunities in commercial real estate.`
          },
          {
            title: "Increased Rental Yields",
            description: `With rising tourist traffic, rental demand in the areas around Al Marjan Island is expected to soar, leading to higher rental yields for investors.`
          },
          {
            title: "Improved Infrastructure and Connectivity",
            description: `The project will spur major infrastructure improvements, enhancing living experiences and making nearby real estate more appealing.`
          }
        ]
      },
      {
        heading: "Luxury Living: Opportunities on Al Marjan Island and Beyond",
        content: `For those seeking high-end properties, Al Marjan Island will be a prime destination. Luxury Villa and waterfront Apartments will gain in value as the casino resort develops, making it attractive for buyers and investors alike.`
      },
      {
        heading: "Impact on Tourism and Business",
        content: `The Casino Island project will transform Ras Al Khaimah and Dubai into premier global tourism destinations, attracting diverse visitors. The casino will fuel hospitality and tourism sectors, leading to increased business opportunities for hotels, restaurants, and retail outlets.`
      },
      {
        heading: "Real Estate Opportunities: What Investors Should Watch",
        content: `Key factors to consider include proximity to the casino, future infrastructure plans, rental market potential, and commercial real estate growth. Investors should pay attention to these developments to maximize their investment opportunities.`
      },
      {
        heading: "Conclusion",
        content: `Dubai’s Casino Island on Al Marjan Island represents a transformative moment for the UAE’s tourism and property markets. The opportunities for real estate investors are vast, whether in luxury Residential properties or commercial ventures. Now is the time to consider your next move in this rapidly evolving market.`
      }
    ],
    author: {
      name: "Wabweni Brian",
      avatar: "/images/avatar.png",
    },
  },
  
  {
    id: 3,
    title: "Dubai Al Khail Road’s New Junction: A Catalyst for Property Growth and Connectivity.",
    date_posted: "January 29, 2023",
    image: newjunction,
    sections: [
      {
        heading: "A Strategic Infrastructure Development",
        content: `Al Khail Road (E44) is one of Dubai’s key arterial highways, connecting vital areas such as Business Bay, Dubai Marina, and Downtown Dubai to the expanding suburbs. The newly introduced junction is designed to handle the growing traffic demands of Dubai’s booming Residential and business sectors, offering a faster, more efficient route for commuters. Strategically positioned, this junction links new Residential communities, business hubs, and leisure areas, further improving accessibility and connectivity.`
      },
      {
        heading: "Impact on Real Estate Development",
        content: `Infrastructure projects like the new Al Khail Road junction have a direct impact on the value and demand for real estate. Here’s how this junction upgrade is shaping the property market:`,
        points: [
          {
            title: "Increased Accessibility for Key Residential Areas",
            description: `The new junction significantly improves access to sought-after neighborhoods like Meydan, MBR City, Jumeirah Village Circle (JVC), and Al Barsha South, making these areas more attractive to homeowners and investors.`
          },
          {
            title: "Enhanced Commercial Property Opportunities",
            description: `With improved road networks, businesses in areas like Business Bay and Dubai Hills Estate will flourish, increasing demand for commercial properties, including offices and retail spaces.`
          },
          {
            title: "Boost to Rental Yields and Property Values",
            description: `Increased property values in adjacent areas are expected, leading to higher rental yields for investors, especially in locations that were previously seen as less accessible.`
          }
        ]
      },
      {
        heading: "Reduced Commute Times and Enhanced Quality of Life",
        content: `The new junction on Al Khail Road aligns with Dubai’s goals of improving the quality of life for residents by reducing travel times and congestion, allowing more time to enjoy the city’s lifestyle offerings.`
      },
      {
        heading: "Investment Opportunities Around the New Junction",
        content: `Key areas to watch include:`,
        points: [
          {
            title: "Dubai Hills Estate",
            description: `Known for its luxurious Villa and green spaces, this area will become even more desirable with improved accessibility.`
          },
          {
            title: "Meydan",
            description: `Home to prestigious developments, Meydan will attract high-net-worth individuals looking for top-tier residences.`
          },
          {
            title: "Jumeirah Village Circle (JVC)",
            description: `A rapidly growing area known for affordable living, JVC is well-positioned for young professionals and families.`
          }
        ]
      },
      {
        heading: "Long-Term Value for Investors",
        content: `Investing in areas benefiting from new infrastructure developments can lead to long-term value appreciation. Properties near these upgrades will enjoy increased demand and higher returns, making them a smart choice for a profitable real estate portfolio.`
      },
      {
        heading: "Conclusion",
        content: `The new junction on Al Khail Road represents a significant step forward in Dubai’s infrastructure development plans. By enhancing accessibility and creating exciting opportunities for property investors, now is the time to consider the potential of properties in these well-connected areas.`
      }
    ],
    author: {
      name: "Wabweni Brian",
      avatar: "/images/avatar.png",
    },
  },
  
  {
    id: 4,
    title: "Dubai South: The Gateway to Dubai’s Future with its New Airport.",
    date_posted: "January 29, 2023",
    image: dubaiairport,
    sections: [
      {
        heading: "A New Global Aviation Hub",
        content: `Al Maktoum International Airport, located in Dubai South, is poised to become a global aviation powerhouse. Once fully operational, the airport will have the capacity to handle over 260 million passengers annually, making it the largest airport in the world. This project enhances Dubai’s connectivity and positions it as a critical hub for passenger and cargo traffic, leading to a surge in demand for real estate in surrounding areas.`
      },
      {
        heading: "Dubai South: A Growing City within a City",
        content: `Spanning 145 square kilometers, Dubai South is designed to provide a mix of Residential, commercial, and logistics spaces. Home to the Expo 2020 site and major logistics hubs, it is becoming a sought-after location for businesses and expatriates, with its strategic location near key highways and industrial zones.`
      },
      {
        heading: "Real Estate Opportunities in Dubai South",
        content: `The development of Dubai South’s new airport positively impacts the real estate market, offering a range of investment opportunities:`,
        points: [
          {
            title: "Residential Properties: Ideal for Professionals and Families",
            description: `With master-planned communities like The Villages, Dubai South offers affordable Apartments and Villa, making it attractive for families and professionals. Investors can expect strong rental yields due to increasing demand.`
          },
          {
            title: "Commercial Properties: A Thriving Business Hub",
            description: `The area’s proximity to Al Maktoum International Airport makes it ideal for logistics and trade. Investors can find opportunities in office spaces and warehouses within the Business Park Free Zone.`
          },
          {
            title: "Industrial and Logistics Properties: Capitalizing on Trade",
            description: `The Dubai South Logistics District aims to become a key player in global trade, offering long-term potential for investing in industrial warehouses and logistics facilities.`
          }
        ]
      },
      {
        heading: "Connectivity and Infrastructure",
        content: `Dubai South boasts exceptional connectivity to major highways and is set to benefit from future metro extensions and public transport improvements, enhancing accessibility for residents and businesses. Ongoing infrastructure developments like schools and shopping centers will further improve the quality of life.`
      },
      {
        heading: "Long-Term Investment Potential",
        content: `The expansion of Al Maktoum International Airport and Dubai South’s development signal long-term growth in property demand. As the area attracts more residents and businesses, property values are expected to rise, making it a smart choice for investors.`
      },
      {
        heading: "Conclusion",
        content: `The development of Dubai South and the Al Maktoum International Airport marks a major milestone in Dubai’s growth plans. With its strategic location and focus on aviation and logistics, Dubai South presents exciting investment opportunities across various sectors.`
      }
    ],
    author: {
      name: "Wabweni Brian",
      avatar: "/images/avatar.png",
    },
  },
  
  {
    id: 5,
    title: "Dubai Formula 1 Racing: A New Frontier for Property Investment and Lifestyle.",
    date_posted: "January 29, 2023",
    image: formula1,
    sections: [
      {
        heading: "Dubai's Vision: Formula 1 Racing at the Heart of the City",
        content: `Dubai is synonymous with luxury, tourism, and entertainment, making it a natural candidate for hosting Formula 1 races. This prestigious motorsport event draws millions of fans and promises significant boosts to the local economy and tourism. With its world-class infrastructure and ability to create unforgettable experiences, Dubai is poised to become a premier F1 destination.`
      },
      {
        heading: "Impact on the Real Estate Market",
        content: `The introduction of Formula 1 racing in Dubai could catalyze significant changes in the property market:`,
        points: [
          {
            title: "Surge in Property Demand Around Key Racing Venues",
            description: `Properties near the proposed F1 track, especially luxury Apartments and Villa, will likely see increased demand, similar to high-demand areas around the Monaco Grand Prix.`
          },
          {
            title: "Increased Rental Yields and Short-Term Rentals",
            description: `The influx of affluent visitors during F1 weekends will create high demand for short-term rentals, leading to significant rental yield opportunities for property owners.`
          },
          {
            title: "Luxury Property Developments and Racing Lifestyle Communities",
            description: `Developers will likely create racing-themed luxury properties, offering exclusive amenities and experiences for F1 enthusiasts. This could lead to new high-end Residential projects and branded residences.`
          },
          {
            title: "Commercial Real Estate Growth",
            description: `F1 events attract global sponsorships, boosting demand for commercial properties in hospitality and entertainment sectors, creating attractive investment opportunities.`
          }
        ]
      },
      {
        heading: "A Boost to Tourism and Infrastructure",
        content: `The arrival of F1 racing will enhance Dubai's tourism industry, drawing visitors for both the race and the luxury lifestyle. Infrastructure improvements will further increase property values, making the surrounding areas prime for real estate investment.`
      },
      {
        heading: "Long-Term Impact on Property Values",
        content: `Hosting Formula 1 racing will elevate Dubai’s profile as a luxury destination, leading to sustained growth in property values, particularly in premium neighborhoods. Early investors are likely to reap significant benefits as demand rises.`
      },
      {
        heading: "Conclusion",
        content: `Dubai’s entry into Formula 1 Racing heralds a new era for its tourism and real estate sectors. With immense opportunities in luxury real estate and short-term rentals, now is the time for investors to explore the exciting prospects around Dubai’s future F1 venue. The potential for increased property values and rental yields makes this a prime opportunity in Dubai’s evolving market.`
      }
    ],
    author: {
      name: "Wabweni Brian",
      avatar: "/images/avatar.png",
    },
  },
  
  {
    id: 6,
    title: "Dubai Women’s T20 World Cup: A Catalyst for Real Estate Investment.",
    date_posted: "January 29, 2023",
    image: t20worldcup,
    sections: [
      {
        heading: "Global Spotlight on Dubai",
        content: `As Dubai prepares to host the Women’s T20 World Cup, the city will draw cricket fans, athletes, and media from around the globe. The event will highlight Dubai’s world-class infrastructure and luxury accommodations, driving demand for short-term rentals and hotels. Prime locations such as Dubai Sports City, Jumeirah Village Circle (JVC), and Motor City will see increased interest from visitors.`
      },
      {
        heading: "Increased Demand for Short-Term Rentals",
        content: `Major sporting events create significant demand for short-term accommodations. Property owners in neighborhoods near cricket venues can expect higher occupancy rates and premium pricing on rental platforms like Airbnb. Areas such as Dubai Sports City and JVC will benefit greatly from the influx of visitors.`
      },
      {
        heading: "Long-Term Value Appreciation",
        content: `The Women’s T20 World Cup will have lasting positive effects on Dubai’s real estate market, reinforcing its status as a global city with excellent infrastructure and a luxurious lifestyle. This will attract international investors and expatriates, particularly in prime areas like Downtown Dubai, Palm Jumeirah, and Dubai Hills Estate.`
      },
      {
        heading: "Impact on Hospitality and Tourism",
        content: `Dubai's hospitality sector will see a surge in demand, especially for hotels and serviced Apartments near the tournament venues. Investors in the hospitality market can capitalize on this high demand, particularly for upscale properties catering to international visitors.`
      },
      {
        heading: "Key Areas to Watch for Investment",
        content: `Several areas in Dubai are poised for significant benefits from the Women’s T20 World Cup, making them prime spots for real estate investment:`,
        points: [
          {
            title: "Dubai Sports City",
            description: `As the epicenter of the event, this area will see increased demand for both short-term rentals and long-term investments.`
          },
          {
            title: "Jumeirah Village Circle (JVC)",
            description: `Offering affordable housing options and great connectivity, JVC is ideal for investors looking for high rental yields.`
          },
          {
            title: "Dubai Marina and Downtown Dubai",
            description: `These luxury areas will attract affluent visitors and investors seeking premium properties.`
          }
        ]
      },
      {
        heading: "Conclusion: A Winning Opportunity for Property Investors",
        content: `The Women’s T20 World Cup in Dubai offers a unique opportunity for property investors to capitalize on the surge in demand for accommodations. With international visitors flocking to the city, now is the time to explore investments in rental properties, vacation homes, and luxury residences. The event promises significant returns as Dubai solidifies its status as a global sports and entertainment hub.`
      }
    ],
    author: {
      name: "Wabweni Brian",
      avatar: "/images/avatar.png",
    },
  },
  
  {
    id: 7,
    title: "Agency Is the Capacity of an Actor to Act in a Given Context",
    date_posted: "January 29, 2023",
    image: "/images/blog/blog (7).jpg",
    sections: [
      {
        heading: "Understanding Agency",
        content: `Agency refers to the capacity of an individual or group to act independently and make their own choices. In various contexts, agency can denote the ability to influence outcomes and exercise power, whether in personal decisions, social interactions, or broader societal structures.`
      },
      {
        heading: "The Importance of Agency",
        content: `Agency plays a critical role in personal development and empowerment. It allows individuals to navigate challenges, pursue goals, and assert their rights. In societal contexts, agency can drive change and foster resilience within communities.`
      },
      {
        heading: "Agency in Architecture and Design",
        content: `In the fields of architecture and design, agency can manifest in how spaces are utilized and experienced by individuals. The design of environments can enhance or limit a person's sense of agency, influencing how they interact with their surroundings.`
      },
      {
        heading: "Challenges to Agency",
        content: `Various factors can impede agency, including systemic barriers, socio-economic constraints, and cultural expectations. Understanding these challenges is essential for fostering environments that support agency and empower individuals to act.`
      },
      {
        heading: "Conclusion",
        content: `Recognizing and enhancing agency is vital for personal growth and societal progress. By understanding the concept of agency, individuals can better navigate their environments, assert their choices, and contribute to meaningful change.`
      }
    ],
    author: {
      name: "Wabweni Brian",
      avatar: "/images/avatar.png",
    },
  },
  

];

export const teamMembers = [
  {
    id: 1,
    name: "Wabz Braize",
    role: "Front-end developer",
    image: "/images/avatar.png",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "Backend  engineer",
    image: "/images/avatar-1.png",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "UI Designer",
    image: "/images/avatar-2.png",
  },
  {
    id: 4,
    name: "John Doe",
    role: "Web developer",
    image: "/images/avatar-3.png",
  },
  {
    id: 5,
    name: "John Doe",
    role: "Web developer",
    image: "/images/avatar-3.png",
  },
  {
    id: 6,
    name: "Raven Kent",
    role: "UI Designer",
    image: "/images/avatar-2.png",
  },
  {
    id: 7,
    name: "Ethan Hunt",
    role: "Backend  engineer",
    image: "/images/avatar-1.png",
  },
  {
    id: 8,
    name: "Wabz Braize",
    role: "Front-end developer",
    image: "/images/avatar.png",
  },
];

export const services = [
  {
    id: 1,
    name: "Sell Your Home",
    icon: <BiBarChart />,
    text: "Unlock the potential of your property with our expert services to sell your home quickly and effortlessly!",
  },
  {
    id: 2,
    name: "Evalute Your Home",
    icon: <BiHomeAlt />,
    text: "Get an accurate and comprehensive assessment of your home's value with our expert evaluation services!",
  },
  {
    id: 3,
    name: "Free Apraisal",
    icon: <BiFullscreen />,
    text: "Receive a complimentary appraisal to discover your home's true market value!",
  },
  {
    id: 4,
    name: "Trusted Agency",
    icon: <BiShieldAlt2 />,
    text: "Partner with a trusted agency committed to your real estate success!",
  },
  // 
];

// export const projects = [
//   {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   },
//   {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury Apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   },
// ];

export const brands = [
  "/images/brands/airbnb.png",
  "/images/brands/cisco.png",
  "/images/brands/ebay.png",
  "/images/brands/microsoft.png",
  "/images/brands/uber.png",
];

export const focus = [
  {
    id: 1,
    name: "Buy a New Home",
    icon: <BiHomeAlt />,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod earum commodi provident est ex similique.",
  },
  {
    id: 2,
    name: "Sell a Home",
    icon: <BiMoney />,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod earum commodi provident est ex similique.",
  },
  {
    id: 4,
    name: "Rent a Home",
    icon: <BiBuildings />,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod earum commodi provident est ex similique.",
  },
];



export const propertyTypes = [
  {
    id: 1,
    name: "Apartment",
    number: 20,
  },
  {
    id: 2,
    name: "house",
    number: 32,
  },
  {
    id: 3,
    name: "industrial",
    number: 25,
  },
  {
    id: 4,
    name: "office villa",
    number: 62,
  },
  {
    id: 5,
    name: "luxury home",
    number: 22,
  },
  {
    id: 6,
    name: "land",
    number: 15,
  },
  {
    id: 7,
    name: "Rental",
    number: 10,
  },
  {
    id: 8,
    name: "studio",
    number: 17,
  },
];
export const priceRanges = [
  {
    id: 1,
    name: "low Budget",
    range: "$100k - 300k",
  },
  {
    id: 2,
    name: "medium budget",
    range: "300k - 500k",
  },
  {
    id: 3,
    name: "high budget",
    range: "500k - 1M",
  },
];

export const socials = [
  <FaFacebook />,
  <FaTwitter />,
  <FaInstagram />,
  <FaLinkedin />,
  <FaBehance />,
  <FaDribbble />,
  <FaYoutube />,
  <FaVimeo />,
];

export const ratings = [
  {
    id: 1,
    image: "/images/property (14).jpg",
    rating: 4.3,
    name: "Luxury mansion in Oregon",
    price: 100,
  },
  {
    id: 2,
    image: "/images/property (26).jpg",
    rating: 4.3,
    name: "Luxury mansion in Oregon",
    price: 100,
  },
  {
    id: 3,
    image: "/images/property (16).jpg",
    rating: 4.3,
    name: "Luxury mansion in Oregon",
    price: 100,
  },
  {
    id: 4,
    image: "/images/property (18).jpg",
    rating: 4.3,
    name: "Luxury mansion in Oregon",
    price: 100,
  },
];

export const tags = [
  "Popular",
  "Luxury Villa",
  "Apartment",
  "Interior Design",
  "Architect",
  "Condor",
  "Home",
];

export const faqs = [
  {
    id: 1,
    question: "How can we help?",
    response:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto nisi, molestias ipsam dolorum laudantium corrupti optio cupiditate libero odit cum, labore ab sint officiis reiciendis quisquam perferendis, facilis facere iste inventore architecto blanditiis suscipit. Voluptatibus.",
  },
  {
    id: 2,
    question: "How can I make refund from your website?",
    response:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto nisi, molestias ipsam dolorum laudantium corrupti optio cupiditate libero odit cum, labore ab sint officiis reiciendis quisquam perferendis, facilis facere iste inventore architecto blanditiis suscipit. Voluptatibus.",
  },
  {
    id: 3,
    question: "Do you store any of my information?",
    response:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto nisi, molestias ipsam dolorum laudantium corrupti optio cupiditate libero odit cum, labore ab sint officiis reiciendis quisquam perferendis, facilis facere iste inventore architecto blanditiis suscipit. Voluptatibus.",
  },
  {
    id: 4,
    question: "Should I talk to the bank before booking a home?",
    response:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto nisi, molestias ipsam dolorum laudantium corrupti optio cupiditate libero odit cum, labore ab sint officiis reiciendis quisquam perferendis, facilis facere iste inventore architecto blanditiis suscipit. Voluptatibus.",
  },
  {
    id: 5,
    question: "How do i make payments using my credit card?",
    response:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto nisi, molestias ipsam dolorum laudantium corrupti optio cupiditate libero odit cum, labore ab sint officiis reiciendis quisquam perferendis, facilis facere iste inventore architecto blanditiis suscipit. Voluptatibus.",
  },
  {
    id: 6,
    question: "How do i link multiple accounts with my phone?",
    response:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto nisi, molestias ipsam dolorum laudantium corrupti optio cupiditate libero odit cum, labore ab sint officiis reiciendis quisquam perferendis, facilis facere iste inventore architecto blanditiis suscipit. Voluptatibus.",
  },
];






export const categories = [
  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartment", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },
  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },  {
    id: 1,
    name: "Duplex",
    number: 20,
    cat: "duplex",
    image: duplex,
  },
  {
    id: 2,
    name: "Townhouse", // Corrected spelling
    number: 23,
    cat: "townhouse",
    image: townhouse,
  },
  {
    id: 3,
    name: "Apartments", // Corrected spelling
    number: 36,
    cat: "Apartment", // Corrected spelling
    
    image:img10,
  },
  {
    id: 4,
    name: "Villa",
    number: 12,
    cat: "villa",
    image: "/images/property (10).jpg",
  },
  {
    id: 5,
    name: "Office",
    number: 14,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 6, // Added missing id
    name: "Shops",
    number: 14,
    cat: "shop",
    image: shops,
  },
  {
    id: 7, // Changed to a unique id
    name: "Warehouses", // Corrected spelling
    number: 14,
    cat: "warehouses",
    image: warehouse,
  },
];
;











// description: [
//   {
//     heading: "Investor’s Deal | Exclusive | Near Lagoon",
//     paragraph: `
//       *Seaboard Properties* is delighted to present this exclusive and stunning 5-bedroom + maid villa in the Morocco cluster of Damac Lagoons. This expansive property is perfect for both first-time buyers and seasoned investors, as well as those seeking a luxurious residence to call home.`,
//     content: ""
//   },
//   {
//     heading: "Unit Features:",
//     content: `- 5 Bedrooms + Maid's Room\n- 5 Bathrooms\n- Balcony\n- 2 Covered Parking Spaces\n- Walk-In Closet\n- Built-In Wardrobes\n- Expansive Living Area\n- Bright Dining Area\n- Modern Kitchen\n- Private Garden\n- Close to Park and Amenities`
//   },
//   {
//     heading: "Key Highlights:",
//     content: `- Largest lagoon across all phases\n- Private garden\n- Lush green landscapes\n- Natural mountains and caves\n- Fully fitted kitchens and washrooms\n- Built-in wardrobes\n- Outdoor spa\n- Floating cinema\n- Outdoor aqua library\n- Prime location\n- 24-hour security in a gated community\n- Proximity to international schools and healthcare centers`
//   },
//   {
//     heading: "Location & Connectivity:",
//     content: `- 8 minutes to Hessa Street\n- 10 minutes to Dubai Polo & Equestrian Club\n- 12 minutes to Dubai Autodrome\n- 13 minutes to EXPO 2020 site\n- 14 minutes to Dubai International Stadiums\n- 14 minutes to Global Village\n- 20 minutes to Mall of the Emirates\n- 28 minutes to Al Maktoum International Airport`
//   },
//   {
//     heading: "Community Overview",
//     content: `Damac Lagoons is a vibrant community that offers a mix of luxury living and natural beauty, featuring picturesque lagoons and lush landscapes.`
//   },
//   {
//     heading: "Contact Information",
//     content: `*For more details, please contact Seaboard Properties LLC at 050 323 1194 or 04-2359233.*\n**BRN No. 71148 | ORN No. 1903**`
//   }
// ],