import React, { useState, useEffect } from 'react';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSearchParams } from "react-router-dom";

const PurposeDropdown = ({ activeDropdown, handleDropdownToggle, onPurposeSelect }) => {
  const [searchParams] = useSearchParams();
  const [selectedPurpose, setSelectedPurpose] = useState(null);
  
  useEffect(() => {
    const purpose = searchParams.get('purpose');
    if (purpose) {
      setSelectedPurpose(purpose);
      onPurposeSelect(purpose);
    }
  }, [searchParams, onPurposeSelect]);

  const purposes = [
    { value: 'Rent', label: 'Rent' },
    { value: 'Buy', label: 'Buy' },
    { value: 'Commercial Rent', label: 'Commercial Rent' },
    { value: 'Commercial Sale', label: 'Commercial Sale' },
  ];

  const handlePurposeClick = (value) => {
    setSelectedPurpose(value);
    onPurposeSelect(value);
  };

  const resetSelection = () => {
    setSelectedPurpose(null);
    onPurposeSelect(null);
  };

  return (
    <div
      onClick={() => handleDropdownToggle('rentSale')}
      className={`relative flex justify-between border dark:bg-dark-light rounded-lg w-full md:w-fit px-2 py-3 bg-slate-100 items-center cursor-pointer ${selectedPurpose ? 'border bg-primary/10' : ''}`}
    >
      <button className="border-b-2 border-transparent">
        {selectedPurpose ? selectedPurpose.charAt(0).toUpperCase() + selectedPurpose.slice(1) : "Purpose"}
      </button>
      {activeDropdown === 'rentSale' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}

      {activeDropdown === 'rentSale' && (
        <div className="bg-white dark:bg-dark-light border absolute top-full left-0 w-60 p-4 rounded shadow-md z-10">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              {purposes.map(({ value, label }) => (
                <p
                  key={value}
                  onClick={() => handlePurposeClick(value)}
                  className="p-2 hover:bg-slate-100 dark:hover:bg-dark hover:text-black cursor-pointer"
                  aria-label={`Select ${label}`}
                >
                  {label}
                </p>
              ))}
            </div>
          </div>

          <hr />

          <div className="flex justify-between dark:text-slate-50 mt-2">
            <button onClick={resetSelection} className="text-gray-600 dark:text-slate-50">
              Reset
            </button>
            <div
              onClick={() => handleDropdownToggle(null)}
              className="text-primary cursor-pointer"
              aria-label="Confirm selection"
            >
              <CheckCircleOutlineIcon />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PurposeDropdown;

