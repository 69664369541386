import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField"; 
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RoomOutlinedIcon from '@mui/icons-material/CheckCircleOutline';
import DubaiLocations from "../../../data/dummyData";
import "./Filter.css";

const Filters = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    location: "",
    propertyType: "",
    selectedBeds: null,
    selectedBaths: null,
    minArea: '',
    maxArea: '',
    selectedFilter: "Rent",
  });

const [location , setLocation] = useState("")
  const [activeModal, setActiveModal] = useState(null);

  const bedsButton = [1, 2, 3, 4, 5, 6, 7];
  const bathroomButton = [1, 2, 3, 4, 5];

  const propertyTypesResidential = [
    { value: "Duplex", label: "Duplex" },
    { value: "Apartment", label: "Apartment" },
    { value: "Townhouse", label: "Townhouse" },
    { value: "Villa", label: "Villa" },
  ];

  const propertyTypesCommercial = [
    { value: "Shop", label: "Shop" },
    { value: "Office", label: "Office" },
    { value: "Warehouse", label: "Warehouse" },
  ];

  const toggleModal = (modal) => {
    setActiveModal(prev => (prev === modal ? null : modal));
  };

  const handleFilterClick = (filter) => {
    setFilters((prev) => ({
      ...prev,
      selectedFilter: filter,
      propertyType: filter === "Commercial Rent" ? null:  prev.propertyType,
      selectedBeds: filter === "Commercial Rent" ? null : prev.selectedBeds,
      selectedBaths: filter === "Commercial Rent" ? null : prev.selectedBaths,
selectedArea: filter === "Residential" ? null :prev.selectedArea,
    }));
  };

  const handleSearch = () => {
    const { location, propertyType, selectedBeds, selectedBaths, minArea, maxArea, selectedFilter } = filters;
  
    // Create a URLSearchParams object
    const params = new URLSearchParams();
  
    // Conditionally append parameters
    if (location) params.append('location', location);
    if (propertyType) params.append('propertyType', propertyType);
    if (selectedBeds !== null) params.append('beds', selectedBeds);
    if (selectedBaths !== null) params.append('baths', selectedBaths);
    if (minArea) params.append('minArea', minArea);
    if (maxArea) params.append('maxArea', maxArea);
    if (selectedFilter) params.append('purpose', selectedFilter);
  
    // Navigate with the constructed URL
    navigate(`/property?${params.toString()}`);
  };
  

  const getAreaLabel = () => {
    const { minArea, maxArea } = filters;
    if (minArea && maxArea) {
      return `${minArea.slice(0,3)}.. - ${maxArea.slice(0,3)}.. sqft`;
    } else if (minArea) {
      return `Min: ${minArea.slice(0,3)}.. sqft`;
    } else if (maxArea) {
      return `Max: ${maxArea.slice(0,3)}.. sqft`;
    }
    return "Area (sqft)";
  };

  return (
    <div className="md:max-w-[80%] w-full z-30 mx-auto relative p-6">
     
      <div className="flex-col md:bg-black/20 dark:bg-black/20 backdrop-blur-4xl md:-mt-40 -mt-48 gap-x-4 flex-center-between gap-y-4 md:gap-y-0 md:flex-row card card-shadow dark:shadow-none">
        <div className="w-full flex flex-col gap-4">
        <div className="dark:bg-dark-light bg-white w-full md:w-6/12 rounded-full outline-none h-10 m-auto flex justify-between items-center shadow-lg">
  <button
    className={`w-full h-full font-semibold rounded-l-full ${filters.selectedFilter === "Rent" ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'}`}
    onClick={() => handleFilterClick("Rent")}
  >
    Rent
  </button>
  <button
    className={`w-full h-full font-semibold md:block hidden  ${filters.selectedFilter === "Residential" ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'} border-l`}
    onClick={() => handleFilterClick("Residential")}
  >
    Residential
  </button>
  <button
    className={`w-full h-full font-semibold  ${filters.selectedFilter === "Commercial Rent" ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'} border-l`}
    onClick={() => handleFilterClick("Commercial Rent")}
  >
    Commercial
  </button>
  <button
    className={`w-full h-full font-semibold rounded-r-full ${filters.selectedFilter === "Buy" ? 'bg-primary text-white' : 'hover:bg-primary hover:text-white'} border-l`}
    onClick={() => handleFilterClick("Buy")}
  >
    Buy
  </button>
</div>


          <div className="bg-white dark:bg-dark-light w-10/12 rounded-full h-12 dark:border-none m-auto flex items-center justify-between pl-5 shadow-lg">
            <div className="flex items-center dark:text-white">
              <span className="md:block hidden">

              <SearchIcon />
              </span>
              <Autocomplete
  disablePortal
  options={DubaiLocations}
  getOptionLabel={(option) => option.title}
  onChange={(event, newValue) => {
    // Update filters with the selected location
    setFilters((prev) => ({
      ...prev,
      location: newValue ? newValue.value : "", // Use newValue.value for the selected location
    }));
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      variant="outlined"
      placeholder="Location"
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      sx={{
        width: "250px",
        border: "none",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "none",
          },
        },
      }}
    />
  )}
  renderOption={(props, option) => (
    <li className={`dark:bg-dark-light dark:text-white ${props.className}`} {...props}>
      <RoomOutlinedIcon style={{ marginRight: 8 }} />
      {option.title}
    </li>
  )}
/>

            </div>

            <span className="hidden md:block">|</span>
            <div onClick={() => toggleModal('propertyType')} className="flex justify-between md:block hidden items-center relative">
              <button className="border-b-2 border-transparent font-bold">{filters.propertyType || "Property Type"}</button>
              {activeModal === 'propertyType' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              {activeModal === 'propertyType' && (
                <div className="bg-white dark:bg-dark-light border absolute top-10 w-[200px] p-2 rounded shadow-md z-10">
                  <div className="flex flex-col gap-4">
                    {(filters.selectedFilter === "Commercial Rent" ? propertyTypesCommercial : propertyTypesResidential).map(({ value, label }) => (
                      <p
                        key={value}
                        onClick={() => {
                          setFilters({ ...filters, propertyType: label });
                          toggleModal('propertyType');
                        }}
                        className={`p-2 hover:bg-slate-100 dark:hover:bg-dark hover:text-black cursor-pointer ${filters.propertyType === label ? 'bg-primary text-white' : ''}`}
                      >
                        {label}
                      </p>
                    ))}
                  </div>
                  <hr />
                  <div className="flex justify-between dark:text-slate-50 mt-2">
                    <button className="text-gray-600 dark:text-slate-50" onClick={() => setFilters({ ...filters, propertyType: "" })}>Reset</button>
                    <div onClick={() => toggleModal('propertyType')} className="text-primary cursor-pointer">
                      <CheckCircleOutlineIcon />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {["Rent", "Residential", "Buy"].includes(filters.selectedFilter) && (
              <>
                <span className="hidden md:block">|</span>
                <div onClick={() => toggleModal('bedsBath')} className="flex justify-between items-center md:block hidden relative">
                  <button className="border-b-2 border-transparent font-bold">
                    {`${filters.selectedBeds || 0} Beds & ${filters.selectedBaths || 0} Baths`}
                  </button>
                  {activeModal === 'bedsBath' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  {activeModal === 'bedsBath' && (
                    <div className="bg-white dark:bg-dark-light border absolute top-10 w-[400px] p-4 rounded shadow-md z-10">
                      <div className="flex flex-col gap-4">
                        <div>
                          <h3 className="font-bold mb-2">Bedrooms</h3>
                          <div className="flex flex-wrap gap-2">
                            {bedsButton.map((bed) => (
                              <button
                                key={bed}
                                onClick={() => {
                                  setFilters((prev) => ({ ...prev, selectedBeds: bed }));
                                  toggleModal('bedsBath');
                                }}
                                className={`p-2 border rounded-full ${filters.selectedBeds === bed ? 'bg-primary text-white ' : 'bg-gray-200 dark:bg-dark-light'} w-10 transition-colors hover:bg-gray-300`}
                              >
                                {bed}
                              </button>
                            ))}
                          </div>
                        </div>

                        <div className="mb-4">
                          <h3 className="font-bold mb-2">Bathrooms</h3>
                          <div className="flex flex-wrap gap-2">
                            {bathroomButton.map((bath) => (
                              <button
                                key={bath}
                                onClick={() => {
                                  setFilters((prev) => ({ ...prev, selectedBaths: bath }));
                                  toggleModal('bedsBath');
                                }}
                                className={`p-2 border rounded-full w-10 ${filters.selectedBaths === bath ? 'bg-primary text-white' : 'bg-gray-200 dark:bg-dark-light'} transition-colors hover:bg-gray-300`}
                              >
                                {bath}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="flex justify-between dark:text-slate-50 mt-2">
                        <button className="text-gray-600 dark:text-slate-50" onClick={() => setFilters({ ...filters, selectedBeds: null, selectedBaths: null })}>Reset</button>
                        <div onClick={() => toggleModal('bedsBath')} className="text-primary cursor-pointer">
                          <CheckCircleOutlineIcon />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {filters.selectedFilter === "Commercial Rent" && (
              <>
                <span className="hidden md:block">|</span>
                <div onClick={() => toggleModal('areaSquare')} className="flex justify-between md:block hidden items-center relative">
  <button className="border-b-2 border-transparent font-bold">
    {getAreaLabel()}
  </button>
  {activeModal === 'areaSquare' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
  {activeModal === 'areaSquare' && (
    <div
      onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside it
      className="bg-white dark:bg-dark-light border absolute top-10 w-[420px] p-4 rounded shadow-md z-10"
    >
      <h3 className="font-bold mb-2">{getAreaLabel()}</h3>
      <div className="flex flex-wrap gap-2">
        <div>
          <div className="flex justify-center gap-3 mb-3">
            <input
              className="border h-10 rounded-md pl-2 number-input dark:bg-dark-light"
              placeholder='Min Area'
              type="number"
              value={filters.minArea}
              onChange={(e) => setFilters({ ...filters, minArea: e.target.value })}
            />
            <input
              className="border pl-2 rounded-md number-input dark:bg-dark-light"
              placeholder='Max Area'
              type="number"
              value={filters.maxArea}
              onChange={(e) => setFilters({ ...filters, maxArea: e.target.value })}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="flex justify-between dark:text-slate-50 mt-2">
        <button className="text-gray-600 dark:text-slate-50" onClick={() => setFilters({ ...filters, minArea: '', maxArea: '' })}>Reset</button>
        <div onClick={() => toggleModal('areaSquare')} className="text-primary cursor-pointer">
          <CheckCircleOutlineIcon />
        </div>
      </div>
    </div>
  )}
</div>

              </>
            )}

            <button className="btns font-bold h-12 w-32 hidden md:block rounded-r-full overflow-hidden bg-primary text-white" onClick={handleSearch}>
              Search
            </button>
            <button className=" font-bold h-8 w-8 mr-3 block md:hidden rounded-full overflow-hidden bg-primary text-white" onClick={handleSearch}>
            <SearchIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;





    {/* <input
                className="pl-2 dark:bg-dark-light md:w-full w-25"
                type="text"
                value={filters.location}
                onChange={(e) => setFilters({ ...filters, location: e.target.value })}
                placeholder="Enter A Location"
              /> */}












































