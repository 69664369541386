import { BiCreditCard, BiGlobe, BiHomeAlt } from "react-icons/bi";












// [4:34 pm, 26/09/2024] Ashhad Rashid: SALE:
 
// https://www.propertyfinder.ae/en/broker/seaboard-properties-l-l-c-9575?properties%5Bfilter%5Bcategory_id%5D%5D=1
// [4:34 pm, 26/09/2024] Ashhad Rashid: RENT:
 
// https://www.propertyfinder.ae/en/broker/seaboard-properties-l-l-c-9575?properties%5Bfilter%5Bcategory_id%5D%5D=2



const AboutUs = () => {
  return (
    <>
      <div className="pt-16 ">
        <div className="flex flex-wrap ">
          <div className="relative flex-1 basis-[18rem] border  rounded-tr-2xl rounded-tl-2xl rounded-bl-2xl overflow-hidden">
            <img
              src="/images/property (16).jpg"
              alt=""
              className="object-cover w-full h-full "
            />

          </div>
          <div className="relative flex-1 basis-[22rem] pt-10 pl-5">
            <h1 className="heading">OUR MISSION</h1>
            <p className="mt-3">
            Seaboard Properties is dedicated to elevating the standards of Dubai’s real estate industry through an unwavering commitment to excellence, transparency, and innovation. Our mission is to provide clients with a seamless, personalized experience that goes beyond property transactions, fostering trust and long-term partnerships in every interaction. By blending deep market insight with a client-focused approach, we strive to help individuals and businesses alike discover spaces that empower growth, inspire comfort, and define lifestyle. At Seaboard Properties, we are not just shaping Dubai’s skyline—we are building enduring relationships founded on integrity and mutual success.
            </p>
            <div className="mt-4">
              <div className="flex-align-center gap-x-2">
                <div className="icon-box text-primary !bg-primary/20">
                  <BiHomeAlt />
                </div>
                <div>
                  <h1 className="font-semibold capitalize">
                    the perfect residency
                  </h1>

                </div>
              </div>

              <div className="mt-3 flex-align-center gap-x-2">
                <div className="icon-box text-primary !bg-primary/20">
                  <BiGlobe />
                </div>
                <div>
                  <h1 className="font-semibold capitalize">
                    global architect experts
                  </h1>

                </div>
              </div>

              <div className="mt-3 flex-align-center gap-x-2">
                <div className="icon-box text-primary !bg-primary/20">
                  <BiCreditCard />
                </div>
                <div>
                  <h1 className="font-semibold capitalize">
                    total payment transparency
                  </h1>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" pb-10">
        <div className="flex flex-wrap ">

          <div className="relative flex-1 basis-[18rem] pt-10 pl-5">

            <h1 className="heading">OUR VISION</h1>
            <p className="mt-3">
            "At Seaboard Properties, we envision a future where real estate transcends transactions and fosters meaningful connections between people and spaces. We aspire to be Dubai’s most trusted and innovative property partner, setting the benchmark for excellence, integrity, and visionary service. By anticipating the needs of our clients and embracing the evolving dynamics of Dubai’s real estate market, we aim to transform how individuals and businesses find, experience, and value their properties. Our goal is to build a legacy of enduring impact—one that enriches communities, empowers growth, and positions Seaboard Properties as a cornerstone of Dubai’s vibrant future."
            </p>
            <div className="mt-4">
              <div className="flex-align-center gap-x-2">
                <div className="icon-box text-primary !bg-primary/20">
                  <BiHomeAlt />
                </div>
                <div>
                  <h1 className="font-semibold capitalize">
                    the perfect residency
                  </h1>

                </div>
              </div>

              <div className="mt-3 flex-align-center gap-x-2">
                <div className="icon-box text-primary !bg-primary/20">
                  <BiGlobe />
                </div>
                <div>
                  <h1 className="font-semibold capitalize">
                    global architect experts
                  </h1>
                </div>
              </div>

              <div className="mt-3 flex-align-center gap-x-2">
                <div className="icon-box text-primary !bg-primary/20">
                  <BiCreditCard />
                </div>
                <div>
                  <h1 className="font-semibold capitalize">
                    total payment transparency
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="relative flex-1 basis-[22rem] border rounded-br-2xl rounded-tr-2xl rounded-bl-2xl overflow-hidden">
            <img
              src="/images/property (26).jpg"
              alt=""
              className="object-cover w-full h-full  "
            />

          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
