import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import logo from './Assets/images/logo/Blue Minimalist Investment Company Logo.png';
import { FiMoon, FiSun, FiPhone } from "react-icons/fi";
import { toggleDarkMode, uiStore, closeDropdown } from "../src/features/uiSlice";
import { Routes, Route, useLocation } from "react-router-dom";
import BackToTopButton from "./components/common/BackToTopButton";
import Footer from "./components/common/Footer";
import Navbar from "./components/common/Navbar";
import Dropdown from "./components/common/DropDown";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Property from "./pages/Property";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import PageNotFound from "./pages/PageNotFound";
import PropertyDetailList from "./pages/PropertyDetailList";
import Category from "./pages/Category";
import Loader from './components/common/Loader';

function App() {
  const [showButton, setShowButton] = useState(false);
  const { darkMode } = useSelector(uiStore);
  const dispatch = useDispatch();
  const route = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const savedDarkMode = JSON.parse(localStorage.getItem("Seaboard")) ?? false;
    if (savedDarkMode) dispatch(toggleDarkMode());
  }, [dispatch]);

  useEffect(() => {
    const rootDoc = document.querySelector(":root");
    rootDoc.classList.toggle("dark", darkMode);
    localStorage.setItem("Seaboard", JSON.stringify(darkMode));
  }, [darkMode]);

  useEffect(() => {
    const handleScroll = () => setShowButton(window.scrollY > 500);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const titles = {
      "/": "Seaboard Property - Home",
      "/about-us": "Seaboard Property - About Us",
      "/services": "Seaboard Property - Services",
      "/property": "Seaboard Property - Property",
      "/blog": "Seaboard Property - Blog",
      "/contact": "Seaboard Property - Contact",
      "/category": "Seaboard Property - Category",
      "/property/:id": "Seaboard Property - Details",
    };
    document.title = titles[route.pathname] || "Seaboard - Page Not Found";
  }, [route]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [route]);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleDarkMode = () => dispatch(toggleDarkMode());
  const handleCloseDropdown = () => dispatch(closeDropdown());

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Navbar />
          <Dropdown />
          <div className="min-h-screen pb-10" onClick={handleCloseDropdown} onMouseOver={handleCloseDropdown}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/property" element={<Property />} />
              <Route path="/property/:id" element={<PropertyDetailList />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/category" element={<Category />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
          <FooterWrapper />
          <BackToTopButton showButton={showButton} />
        </div>
      )}
      <DarkModeToggle darkMode={darkMode} handleDarkMode={handleDarkMode} />
      <ContactButtons />
    </>
  );
}

const FooterWrapper = () => (
  <div className="px-[2%] md:px-[6%] border bg-primary/20 text-black shadow-lg backdrop-blur-2xl border-card-dark rounded-tl-[100px] rounded-tr-[100px]">
    <Footer />
  </div>
);

const DarkModeToggle = ({ darkMode, handleDarkMode }) => (
  <div
    className="fixed bg-primary z-10 shadow-md top-28 right-8 icon-box text-white dark:bg-dark-light hover:shadow-lg hover:bg-black"
    onClick={handleDarkMode}
    aria-label="Toggle Dark Mode"
    role="button"
    tabIndex={0}
    onKeyPress={(e) => e.key === 'Enter' && handleDarkMode()}
  >
    {darkMode ? <FiMoon /> : <FiSun />}
  </div>
);

const ContactButtons = () => (
  <div className="fixed bg-primary z-50 shadow-md my-auto bottom-28 right-9 h-14 w-14 rounded-full text-white dark:bg-dark-light hover:shadow-lg hover:bg-secondary flex flex-col items-center justify-center">
    <a href="tel:+971 42 359 233
" className="mb-1">
      <FiPhone className="text-white text-2xl mt-4 cursor-pointer" />
    </a>
    <FloatingWhatsApp
      phoneNumber="+971 50 366 4079
"
      accountName="SEABOARD PROPERTY"
      avatar={logo}
      className="h-12 w-12"
    />
  </div>
);

export default App;
