import React, { useState, useEffect } from 'react';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSearchParams } from "react-router-dom";

const PropertyTypeDropdown = ({ 
  activeDropdown, 
  handleDropdownToggle, 
  onPropertyTypeSelect, 
  selectedPurpose 
}) => {
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);
  const [searchParams] = useSearchParams();

  const propertyTypes = [
    { value: 'Apartment', label: 'Apartment' },
    { value: 'Villa', label: 'Villa' },
    { value: 'Townhouse', label: 'Townhouse' },
    { value: 'Duplex', label: 'Duplex' },
    { value: 'Shop', label: 'Shop' },
    { value: 'Office', label: 'Office' },
    { value: 'Warehouse', label: 'Warehouse' },
  ];

  // Effect to set initial state based on URL parameters
  useEffect(() => {
    const propertyType = searchParams.get('propertyType');
    if (propertyType) {
      const matchedType = propertyTypes.find(pt => pt.value.toLowerCase() === propertyType.toLowerCase());
      if (matchedType) {
        setSelectedPropertyType(matchedType.value);
        onPropertyTypeSelect(matchedType.value); // Notify parent about selection
      }
    }
  }, [searchParams, onPropertyTypeSelect, propertyTypes]);

  // Filter property types based on selected purpose
  const filteredPropertyTypes = (selectedPurpose === 'Commercial Rent' || selectedPurpose === 'Commercial Sale')
    ? propertyTypes.filter(pt => ['Shop', 'Office', 'Warehouse'].includes(pt.value))
    : propertyTypes.filter(pt => !['Shop', 'Office', 'Warehouse'].includes(pt.value));

  // Effect to reset selected property type when purpose changes
  useEffect(() => {
    setSelectedPropertyType(null); // Reset selection on purpose change
    onPropertyTypeSelect(null); // Inform parent component about the reset
  }, [selectedPurpose, onPropertyTypeSelect]);

  const handlePropertyTypeClick = (value) => {
    setSelectedPropertyType(value);
    onPropertyTypeSelect(value);
    // Optionally, update the URL here if needed
    // history.push(`/property?propertyType=${value}`);
  };

  const resetSelection = () => {
    setSelectedPropertyType(null);
    onPropertyTypeSelect(null);
  };

  return (
    <div
      className={`relative flex justify-between border rounded-lg w-full md:w-fit px-1 dark:bg-dark-light py-3 bg-slate-100 items-center cursor-pointer ${selectedPropertyType ? 'border bg-primary/10' : ''}`}
      onClick={() => handleDropdownToggle('propertyType')}
      aria-haspopup="true"
      aria-expanded={activeDropdown === 'propertyType'}
    >
      <button className={`border-transparent ${selectedPropertyType ? 'border-gray-500' : ''}`}>
        {selectedPropertyType ? filteredPropertyTypes.find(pt => pt.value === selectedPropertyType)?.label : 'Property Type'}
      </button>
      {activeDropdown === 'propertyType' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}

      {activeDropdown === 'propertyType' && (
        <div className="bg-white dark:bg-dark-light border absolute left-0 w-52 p-4 rounded shadow-md z-10" style={{ top: '100%' }}>
          <div className="flex flex-col gap-4">
            <div className="overflow-y-auto">
              <div className="flex flex-col gap-2">
                {filteredPropertyTypes.map(({ value, label }) => (
                  <p
                    key={value}
                    onClick={() => handlePropertyTypeClick(value)}
                    className={`p-2 hover:bg-slate-100 dark:hover:bg-dark hover:text-black cursor-pointer ${selectedPropertyType === value ? 'font-bold' : ''}`}
                    aria-label={`Select ${label}`}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => e.key === 'Enter' && handlePropertyTypeClick(value)}
                  >
                    {label}
                  </p>
                ))}
              </div>
            </div>
          </div>

          <hr />

          <div className="flex justify-between dark:text-slate-50 mt-2">
            <button onClick={resetSelection} className="text-gray-600 dark:text-slate-50">
              Reset
            </button>
            <div
              onClick={() => handleDropdownToggle(null)} // Close dropdown
              className="text-primary cursor-pointer"
              aria-label="Confirm selection"
            >
              <CheckCircleOutlineIcon />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyTypeDropdown;
